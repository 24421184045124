/* eslint-disable */
import { useContext, useEffect, useState } from 'react'
import PlatformLayout from '../../components/PlatformLayout'
import { Grid } from '@mui/material'
import AlertsBarChart from '../Dashboard/AlertsBarChart'
import { OrgContext } from '../../Org'
import {
  getAlertsSummary,
  getIndicatorCounts,
  getLastYearEachMonthAlerts,
  getLatestTwentyAlerts,
  getLatestTwentyIndicators,
  getOpenOrClosedAlertsSummary,
} from '../../apiCalls/dashboardApi'
import { Indicators, OpenOrClosedAlertSummary } from '../Dashboard'
import {
  processAlertsData,
  processAlertsSummary,
  processIndicatorData,
} from '../Dashboard/processDataHelpMethods'
import AlertsPieChart from '../Dashboard/AlertsPieChart'
import HorizontalBarChart from '../Dashboard/HorizontalBarChart'
import LatestAlertDataList from '../Dashboard/LatestAlertDataList'
import LatestIndicatorDataList from '../Dashboard/LatestIndicatorDataList'
import LoadingCircle from '../../components/LoadingCircle.tsx'
import { useApiGet } from '../../apiCalls/useApi'

export default function AlertDashboard() {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [alertsSummary, setAlertsSummary] = useState({
    total: 0,
    high: 0,
    medium: 0,
    low: 0,
  })
  const [openAlertsSummary, setOpenAlertsSummary] = useState<OpenOrClosedAlertSummary>({
    total: 0,
    dataForPie: [],
  })
  const [closedAlertsSummary, setClosedAlertsSummary] = useState<OpenOrClosedAlertSummary>({
    total: 0,
    dataForPie: [],
  })
  const [indicatorData, setIndicatorData] = useState<Indicators>({
    totalIndicator: 0,
    dataForChart: [],
  })
  const [latestTwentyAlerts, setLatestTwentyAlerts] = useState([])
  const [latestTwentyIndicators, setLatestTwentyIndicators] = useState([])
  const [monthAlerts, setMonthAlerts] = useState([])
  const fetchLastYearEachMonthAlerts = useApiGet(getLastYearEachMonthAlerts)
  const fetchAlertsSummary = useApiGet(getAlertsSummary)
  const fetchOpenOrClosedSummary = useApiGet(getOpenOrClosedAlertsSummary)
  const fetchIndicatorCounts = useApiGet(getIndicatorCounts)
  const fetchLatestTwentyAlerts = useApiGet(getLatestTwentyAlerts)
  const fetchLatestTwentyIndicators = useApiGet(getLatestTwentyIndicators)
  const { org } = useContext(OrgContext)
  const appId = org.apps?.[0]?.id

  useEffect(() => {
    if (!appId) {
      return
    }

    const fetchData = async () => {
      setIsLoading(true)
      const [eachMonthAlerts, alertsSummary, openSummary, closedSummary] = await Promise.allSettled(
        [
          fetchLastYearEachMonthAlerts({ appId }),
          fetchAlertsSummary({ appId }),
          fetchOpenOrClosedSummary({ appId, status: 'OPEN' }),
          fetchOpenOrClosedSummary({ appId, status: 'CLOSED' }),
        ]
      )

      const [indicatorCounts, latestTwentyAlerts, latestTwentyIndicators] =
        await Promise.allSettled([
          fetchIndicatorCounts({ appId }),
          fetchLatestTwentyAlerts({ appId }),
          fetchLatestTwentyIndicators({ appId }),
        ])

      if (eachMonthAlerts.status === 'fulfilled') {
        if(!eachMonthAlerts.value || !eachMonthAlerts.value?.data) return
        setMonthAlerts(eachMonthAlerts.value?.data)
      }else {
        return
      }
      if(alertsSummary.status === 'fulfilled'){
        if(!alertsSummary.value || !alertsSummary.value?.data) return
        setAlertsSummary(processAlertsSummary(alertsSummary.value?.data))
      }else {
        return
      }
      if(openSummary.status === 'fulfilled'){
        if(!openSummary.value || !openSummary.value?.data) return
        setOpenAlertsSummary(processAlertsData(openSummary.value?.data))
      }else {
        return
      }
      if(closedSummary.status === 'fulfilled'){
        if(!closedSummary.value || !closedSummary.value?.data) return
        setClosedAlertsSummary(processAlertsData(closedSummary.value?.data))
      }else{
        return
      }
      if(indicatorCounts.status === 'fulfilled'){
        if(!indicatorCounts.value || !indicatorCounts.value?.data) return
        setIndicatorData(processIndicatorData(indicatorCounts.value?.data))
      }else{
        return
      }
      if(latestTwentyAlerts.status === 'fulfilled'){
        if(!latestTwentyAlerts.value || !latestTwentyAlerts.value?.data) return
        setLatestTwentyAlerts(latestTwentyAlerts.value?.data)
      }else{
        return
      }
      if(latestTwentyIndicators.status === 'fulfilled'){
        if(!latestTwentyIndicators.value || !latestTwentyIndicators.value?.data) return
        setLatestTwentyIndicators(latestTwentyIndicators.value?.data)
      }else{
        return
      }
    
      setIsLoading(false)
    }
    fetchData()
  }, [appId])

  if (isLoading || !appId) {
    return <LoadingCircle />
  }

  return (
    <PlatformLayout currentItem='Alert Dashboard'>
      <div
        style={{
          width: '100%',
          height: 'calc(100vh - 120px)',
          overflow: 'auto',
          boxSizing: 'border-box',
          padding: '20px',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <AlertsBarChart
              data={monthAlerts}
              indexBy='month'
              title='Total Alerts'
              totalNumber={alertsSummary?.total}
              subNumbers={{
                high: alertsSummary?.high,
                medium: alertsSummary?.medium,
                low: alertsSummary?.low,
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <AlertsPieChart
              data={openAlertsSummary?.dataForPie}
              title='Total Open Alerts'
              totalNumber={openAlertsSummary?.total}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <AlertsPieChart
              data={closedAlertsSummary?.dataForPie}
              title='Total Closed Alerts'
              totalNumber={closedAlertsSummary?.total}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <HorizontalBarChart
              data={indicatorData?.dataForChart}
              title='Total Indicators'
              totalNumber={indicatorData?.totalIndicator}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <LatestAlertDataList isLoading={isLoading} data={latestTwentyAlerts} />
          </Grid>

          <Grid item xs={12} md={6}>
            <LatestIndicatorDataList isLoading={isLoading} data={latestTwentyIndicators} />
          </Grid>
        </Grid>
      </div>
    </PlatformLayout>
  )
}
