import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import PersonIcon from '@mui/icons-material/Person'
import TableBody from '@mui/material/TableBody'
import dayjs from 'dayjs'
import { useContext } from 'react'
import { Skeleton } from '@mui/material'
import { OrgContext } from '../../Org'

interface PersonInfoProps {
  info: {
    id: string
    name: string
    appId: string
    service: string
    firstAccessTime: string
    lastAccessTime: string
  }
  isLoading: boolean
  type: string
}

export default function PersonInfo({ info, type, isLoading }: PersonInfoProps) {
  const { org } = useContext(OrgContext)
  const appName = org.apps.find((e) => e.id === info.appId)?.name || ''

  return (
    <TableContainer
      className='personInfoTable'
      component={Paper}
      sx={{ width: 'fit-content' }}
      elevation={0}
    >
      <Table sx={{ width: 'fit-content' }} aria-label='person info'>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>
              <div style={{ display: 'flex' }}>
                <PersonIcon /> {type} Information
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component='th' scope='row' sx={{ fontWeight: 'bold' }}>
              ID
            </TableCell>
            <TableCell>{isLoading ? <Skeleton width={200} /> : info.id}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component='th' scope='row' sx={{ fontWeight: 'bold' }}>
              Name
            </TableCell>
            <TableCell>{isLoading ? <Skeleton width={200} /> : info.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component='th' scope='row' sx={{ fontWeight: 'bold' }}>
              App
            </TableCell>
            <TableCell>{isLoading ? <Skeleton width={200} /> : appName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component='th' scope='row' sx={{ fontWeight: 'bold' }}>
              Service
            </TableCell>
            <TableCell>{isLoading ? <Skeleton width={200} /> : info.service}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component='th' scope='row' sx={{ fontWeight: 'bold' }}>
              First Access Time
            </TableCell>
            <TableCell>{isLoading ? <Skeleton width={200} /> : dayjs(info.firstAccessTime).format('YYYY-MM-DDTHH:mm:ssZ')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component='th' scope='row' sx={{ fontWeight: 'bold' }}>
              Last Access Time
            </TableCell>
            <TableCell>{isLoading ? <Skeleton width={200} /> : dayjs(info.lastAccessTime).format('YYYY-MM-DDTHH:mm:ssZ')}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}
