/* eslint-disable */
import dayjs from 'dayjs'
import SearchedResultList from './SearchedResultList'
import { useLocation, useNavigate } from 'react-router-dom'

export default function ClientsResult() {
  const location = useLocation()
  const { clientResult } = location.state
  const navigation = useNavigate()
  const columns = [
    {
      field: 'id',
      headerName: 'Client Id',
      flex: 1,
      renderCell: (params: any) => (
        <div
          className='linkStyle'
          onClick={() => {
            navigation('/client/clientInvestigation', { state: { searchID: params.value } })
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'firstAccessTime',
      headerName: 'First Access Time',
      flex: 1,
      renderCell: (params: any) => {
        return dayjs(params.value).format('YYYY-MM-DD HH:mm:ss')
      },
    },
    {
      field: 'lastAccessTime',
      headerName: 'Last Access Time',
      flex: 1,
      renderCell: (params: any) => {
        return dayjs(params.value).format('YYYY-MM-DD HH:mm:ss')
      },
    },
  ]
  return <SearchedResultList data={clientResult} columns={columns} />
}
