/* eslint-disable */
import { Button, TextField } from '@mui/material'
import '../../globalStyle.scss'
import { useEffect } from 'react'

export default function SearchParameters({
  title,
  searchID,
  firstname,
  lastname,
  handleSearchId,
  handleFirstname,
  handleLastname,
  onSearch,
}: any) {


  return (
    <div className='searchParameterContainer'>
      <div
        style={{
          width: '220px',
          fontWeight: '600',
          height: '33px',
          marginRight: '10px',
          textAlign: 'center',
          lineHeight: '33px',
        }}
      >
        {title}
      </div>

      <TextField
        variant='filled'
        label='Id'
        size='small'
        onChange={handleSearchId}
        sx={{ '& .MuiInputBase-input': { backgroundColor: '#FFFFFF' }, marginRight: '6px' }}
        value={searchID}
      />
      <TextField
        variant='filled'
        label='First Name'
        size='small'
        onChange={handleFirstname}
        sx={{ '& .MuiInputBase-input': { backgroundColor: '#FFFFFF' }, marginRight: '6px' }}
        style={{ marginRight: '5px' }}
        value={firstname}
      />
      <TextField
        variant='filled'
        label='Last Name'
        size='small'
        onChange={handleLastname}
        sx={{ '& .MuiInputBase-input': { backgroundColor: '#FFFFFF' } }}
        value={lastname}
      />

      <Button
        variant='contained'
        className='buttonEnable'
        onClick={onSearch}
        style={{ marginLeft: '10px' }}
      >
        Search
      </Button>
    </div>
  )
}
