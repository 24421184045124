/* eslint-disable */

import AccessTimeline from './AccessTimeline'

export default function TimeLine({ type, timelineData, isLoading }: any) {
  return (
    <div style={{ height: 'calc(100% - 42px)' }}>
      <div style={{ height: '100%' }}>
        <div className='graphBodyContainer'>
          <AccessTimeline timelineData={timelineData} type={type} isLoading={isLoading} />
        </div>
      </div>
    </div>
  )
}
