import { countRateAlertsNumberStyle, countRateAlertsStyle, numberStyle, titleStyle } from "./commonStyle"

export default function GraphTitle({ title, totalNumber, subNumbers={} }: any) {
  const { high, medium, low } = subNumbers

  return (
    <div style={titleStyle}>
      <div style={{ fontSize: '16px' }}>{title}</div>
      <div style={numberStyle}>{totalNumber}</div>
      {title === 'Total Alerts' && (
        <div>
          <div style={countRateAlertsStyle}>
            <div>Number of High Alerts</div>
            <div style={countRateAlertsNumberStyle}>{high || 0}</div>
          </div>

          <div style={countRateAlertsStyle}>
            <div>Number of Medium Alerts</div>
            <div style={countRateAlertsNumberStyle}>{medium || 0}</div>
          </div>

          <div style={countRateAlertsStyle}>
            <div>Number of Low Alerts</div>
            <div style={countRateAlertsNumberStyle}>{low || 0}</div>
          </div>
        </div>
      )}
    </div>
  )
}
