/* eslint-disable */
import { useEffect, useMemo, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@mui/material'
import AppIcon from '@mui/icons-material/Apps'
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined'
import SecurityIcon from '@mui/icons-material/Security'
import BusinessIcon from '@mui/icons-material/Business'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import DvrIcon from '@mui/icons-material/Dvr'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { AlertDetailDialogProps, AlertEvent, AlertType, ResponseData } from '../../utils/interfaces'
import LogDetailsDialog from '../../components/LogDetailsDialog'
import '../../globalStyle.scss'
import { fetchAlert, updateAlertStatus } from '../../apiCalls/alertsApi'
import { useApiGet, useApiPost } from '../../apiCalls/useApi'
import ProviderDailyLogs from "./ProviderDailyLogs";

function AlertDetailDialog({ appId, open, onClose, row, reload }: AlertDetailDialogProps) {
  const [rowValues, setRowValues] = useState<ResponseData | null>(null)
  const [logDialogOpen, setLogDialogOpen] = useState(false)
  const [currentLogIds, setCurrentLogIds] = useState<string[]>([])
  const [confirmation, setConfirmation] = useState('NULL')
  const [status, setStatus] = useState('NULL')
  const [dailyLogDialog, setDailyLogDialog] = useState(false)

  const navigate = useNavigate()

  const fetchAlertData = useApiGet(fetchAlert)
  const callUpdateAlertStatus = useApiPost(updateAlertStatus)

  useEffect(() => {
    if (!row) return
    fetchAlertData({ alertId: row.id, appId }).then((res: any) => {
      setRowValues({ ...row, alertEventGroups: res.data })
      setConfirmation(row.confirmation)
      setStatus(row.status)
    })
  }, [row])

  const statusCanNotChange = confirmation === 'WAITING' || !confirmation;

  const { t } = useTranslation()

  if (!rowValues) {
    return null
  }

  const toInvestigationPage = (role: string, id: string) => {
    if (role === 'Provider') {
      navigate(`/provider/providerInvestigation`, { state: { searchID: id } })
    }
    if (role === 'Client') {
      navigate(`/client/clientInvestigation`, { state: { searchID: id } })
    }
  }

  const handleStatusChange = (event: { target: { value: string } }) => {
    const newStatus = event.target.value as string
    setStatus(newStatus)
  }

  const handleConfirmationChange = (event: { target: { value: string } }) => {
    const newConfirmation = event.target.value as string
    setConfirmation(newConfirmation)
  }

  const isSubmitInvalid = confirmation === 'WAITING' || !confirmation || !status

  const handleSubmit = async () => {
    if (isSubmitInvalid || !rowValues) return
    const err = await callUpdateAlertStatus({
      alertId: rowValues.id,
      status,
      confirmation,
      appId,
    })
    if (err) return
    reload()
  }

  const generalInfoStyle = {
    backgroundColor: '#CCCCCC',
    color: '#000000',
    padding: '15px',
    marginBottom: '20px',
    borderRadius: '10px',
    fontFamily: 'Segoe UI',
    width: '46%',
  }

  const fieldStyle = () => ({
    padding: '10px',
    margin: '10px 0',
    display: 'flex',
    alignItems: 'center',
    width: '25%',
    fontFamily: 'Segoe UI',
  })

  const logDetailStyle = {
    fontSize: '12px',
    fontWeight: '600',
    fontFamily: 'Segoe UI',
  }

  const handleLogCountClick = (logIds: string[]) => {
    setCurrentLogIds(logIds)
    setLogDialogOpen(true)
  }

  const renderAlertEvents = (providerName: string, alertEvents: AlertEvent[]) => (
    <Box display='flex' flexDirection='column' alignItems='stretch'>
      {alertEvents.map((event, i) => (
        <Box key={i} display='flex' flexDirection='column' alignItems='stretch'>
          <Box
            display='flex'
            flexDirection='row'
            flexWrap='wrap'
            justifyContent='flex-start'
            alignItems='center'
          >
            <div
              style={{
                ...fieldStyle(),
                marginRight: '10px',
              }}
            >
              <InfoOutlinedIcon
                style={{
                  marginRight: '3px',
                  color: '#000000',
                }}
              />
              <Typography variant='subtitle2' component='span' style={logDetailStyle}>
                <strong>Client:</strong> {event.clientName} (
                <span
                  className='linkStyle'
                  onClick={() => {
                    toInvestigationPage('Client', event.clientId)
                  }}
                >
                  {event.clientId}
                </span>
                )
              </Typography>
            </div>
            <div
              style={{
                ...fieldStyle(),
                marginRight: '10px',
              }}
            >
              <SecurityIcon
                color='action'
                style={{
                  marginRight: '3px',
                  color: '#000000',
                }}
              />
              <Typography variant='body2' component='span' style={logDetailStyle}>
                <strong>Risk:</strong> {t(`indicatorLevels.${event.level}`)}
              </Typography>
            </div>
            <div style={{ ...fieldStyle() }}>
              <PersonOutlineOutlinedIcon
                color='action'
                style={{
                  marginRight: '3px',
                  color: '#000000',
                }}
              />
              <Typography variant='body2' component='span' style={logDetailStyle}>
                <strong>Provider:</strong> {providerName} (
                <span
                  className='linkStyle'
                  onClick={() => {
                    toInvestigationPage('Provider', event.providerId)
                  }}
                >
                  {event.providerId}
                </span>
                )
              </Typography>
            </div>
            <Button
              variant='outlined'
              sx={{
                '&:hover': {
                  backgroundColor: '#CCCCCC',
                },
              }}
              style={{
                padding: '10px',
                display: 'flex',
                alignItems: 'center',
                width: '135px',
                height: '27px',
                borderRadius: '8px',
                borderColor: '#000000',
              }}
              onClick={() => handleLogCountClick(event.logIds)}
            >
              <DvrIcon
                style={{
                  marginRight: '5px',
                  color: '#000000',
                }}
              />
              <Typography
                variant='body2'
                style={{
                  fontFamily: 'Prosto One',
                  fontSize: '10px',
                  color: '#000000',
                }}
              >
                <strong>Risky Logs:</strong> {event.logIds.length}
              </Typography>
            </Button>
          </Box>
        </Box>
      ))}
    </Box>
  )

  const dialogStyle = {
    width: '90vw',
    maxWidth: '90vw',
    maxHeight: '90vh',
    margin: 'auto',
    backgroundColor: '#FFFFFF',
    borderRadius: '10px',
  }

  const generalInformationText = {
    fontWeight: '500',
    fontSize: '13px',
    fontFamily: 'Segoe UI',
  }

  const statusAndConfirmationMenuStyle = {
    maxWidth: '250px',
    maxHeight: '44px',
    color: '#000000',
    fontWeight: '500',
    fontSize: '13px',
    marginTop: '15px',
    backgroundColor: '#FFFFFF',
  }

  const inputLabelStyle = {
    fontSize: '18px',
    color: '#5B5B5B',
    left: '-12px',
    fontWeight: '600',
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        PaperProps={{ style: dialogStyle }}
        sx={{
          '& ::-webkit-scrollbar': {
            width: '5px',
          },
          '& ::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1',
          },
          '& ::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '5px',
            '&:hover': {
              backgroundColor: '#555',
            },
          },
        }}
      >
        <DialogTitle
          id='alert-dialog-title'
          style={{
            color: '#000000',
            fontSize: '25px',
          }}
        >
          Alert Details
        </DialogTitle>
        <DialogContent>
          {!rowValues ? (
            <h1>Loading...</h1>
          ) : (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Paper style={generalInfoStyle} elevation={0}>
                  <div>
                    <Typography
                      variant='h6'
                      component='span'
                      sx={{
                        fontSize: '15px',
                        color: '#5B5B5B',
                        fontWeight: '600',
                        fontFamily: 'Segoe UI',
                      }}
                    >
                      Alert Information
                    </Typography>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '5px',
                      }}
                    >
                      <AssessmentOutlinedIcon
                        style={{
                          color: '#000000',
                          marginRight: '5px',
                        }}
                      />
                      <Typography variant='body2' component='span' style={generalInformationText}>
                        Risk: {rowValues.riskLevel}
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '5px',
                      }}
                    >
                      <PersonOutlineOutlinedIcon
                        style={{
                          color: '#000000',
                          marginRight: '5px',
                        }}
                      />
                      <Typography variant='body2' component='span' style={generalInformationText}>
                        Provider ID: {rowValues.providerId}
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '5px',
                      }}
                    >
                      <PersonOutlineOutlinedIcon
                        style={{
                          color: '#000000',
                          marginRight: '5px',
                        }}
                      />
                      <Typography variant='body2' component='span' style={generalInformationText}>
                        Provider Name: {rowValues.providerName}
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '5px',
                      }}
                    >
                      <DateRangeOutlinedIcon
                        style={{
                          color: '#000000',
                          marginRight: '5px',
                        }}
                      />
                      <Typography variant='body2' component='span' style={generalInformationText}>
                        Date: {rowValues.date}
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '10px',
                      }}
                    >
                      <AppIcon
                        style={{
                          color: '#000000',
                          marginRight: '5px',
                        }}
                      />
                      <Typography variant='body2' component='span' style={generalInformationText}>
                        Application: {rowValues.appName}
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '5px',
                      }}
                    >
                      <BusinessIcon
                        style={{
                          color: '#000000',
                          marginRight: '5px',
                        }}
                      />
                      <Typography variant='body2' component='span' style={generalInformationText}>
                        Organization: {rowValues.orgName}
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '5px',
                      }}
                    >
                      <Button size="medium" variant="contained" onClick={() => setDailyLogDialog(true)}>
                        Provider logs on {rowValues.date}
                      </Button>
                    </div>
                  </div>
                </Paper>
                <Paper style={generalInfoStyle} elevation={0}>
                  <div>
                    <Typography
                      variant='h6'
                      component='span'
                      sx={{
                        fontSize: '15px',
                        color: '#5B5B5B',
                        fontWeight: '600',
                        fontFamily: 'Segoe UI',
                      }}
                    >
                      Alert Management
                    </Typography>
                    <Box marginTop='20px' display='flex' flexDirection='column'>
                      <FormControl
                        style={{
                          marginRight: '5px',
                          flex: 1,
                        }}
                      >
                        <InputLabel id='confirmation-label' style={inputLabelStyle}>
                          Confirmation
                        </InputLabel>

                        <Select
                          labelId='confirmation-label'
                          value={confirmation}
                          onChange={handleConfirmationChange}
                          style={statusAndConfirmationMenuStyle}
                        >
                          <MenuItem value='TRUE'>True</MenuItem>
                          <MenuItem value='FALSE'>False</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl
                        style={{
                          marginRight: '5px',
                          flex: 1,
                          marginTop: '15px',
                        }}
                      >
                        <InputLabel id='status-label' style={inputLabelStyle}>
                          Status
                        </InputLabel>
                        <Select
                          labelId='status-label'
                          value={status}
                          onChange={handleStatusChange}
                          disabled={statusCanNotChange}
                          style={statusAndConfirmationMenuStyle}
                        >
                          <MenuItem value='OPEN'>Open</MenuItem>
                          <MenuItem value='CLOSED'>Closed</MenuItem>
                        </Select>
                      </FormControl>
                      <div
                        style={{
                          width: '250px',
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Button
                          variant='contained'
                          onClick={handleSubmit}
                          style={{ marginTop: '10px' }}
                          disabled={isSubmitInvalid}
                        >
                          Submit
                        </Button>
                      </div>
                    </Box>
                  </div>
                </Paper>
              </div>

              <Typography variant='h6' gutterBottom>
                Risk Indicators and Logs
              </Typography>
              {rowValues.alertEventGroups.map((alertType: AlertType) => {
                return (
                  <Paper
                    key={alertType.type}
                    elevation={0}
                    style={{
                      padding: '15px',
                      marginBottom: '20px',
                      backgroundColor: '#ECECEC',
                      borderRadius: '15px',
                    }}
                  >
                    <Typography
                      style={{
                        color: '#5B5B5B',
                        fontSize: '15px',
                        fontWeight: '600',
                      }}
                    >
                      {t(`indicatorTypes.${alertType.type}`)}
                    </Typography>
                    {renderAlertEvents(rowValues.providerName, alertType.alertEvents)}
                  </Paper>
                )
              })}
            </>
          )}
        </DialogContent>
      </Dialog>
      <LogDetailsDialog
        appId={appId}
        open={logDialogOpen}
        onClose={() => {
          setLogDialogOpen(false)
        }}
        currentLogIds={currentLogIds}
      />
      <ProviderDailyLogs
        open={dailyLogDialog}
        onClose={() => setDailyLogDialog(false)}
        appId={appId}
        providerId={rowValues.providerId}
        providerName={rowValues.providerName}
        date={rowValues.date}
      />
    </>
  )
}

export default AlertDetailDialog
