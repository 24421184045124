/* eslint-disable */
import { ResponsivePie } from '@nivo/pie'
import GraphTitle from './GraphTitle'
import { pieChartStyle, pieChartTheme } from './commonStyle'
import {CircularProgress} from "@mui/material";

const AlertsPieChart = ({ isLoading, data, title, totalNumber }: any) => {

  const filteredData = data ? data.filter((item: any) => item.value > 0) : []

  const colors: { [key: string]: string } = {
    High: '#D06262',
    Medium: '#858DD6',
    Low: '#51BF91',
  }

  const getLegends = (): any[] => {
    return filteredData.length > 0
      ? [
          {
            anchor: 'bottom',
            direction: 'row',
            justify: false,
            translateX: 0,
            translateY: 56,
            itemsSpacing: 0,
            itemWidth: 100,
            itemHeight: 18,
            itemTextColor: '#999',
            itemDirection: 'left-to-right',
            itemOpacity: 1,
            symbolSize: 13,
            symbolShape: 'circle',
            effects: [
              {
                on: 'hover',
                style: {
                  itemTextColor: '#000',
                },
              },
            ],
          },
        ]
      : []
  }

  return (
    <div>
      <GraphTitle title={title} totalNumber={totalNumber} />
      <div style={pieChartStyle}>
        {isLoading ?
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}>
            <CircularProgress sx={{color: '#A4A4A4'}} size='4rem'/>
          </div>
          : filteredData.length > 0 ? (
            <ResponsivePie
              data={filteredData}
              margin={{
                top: 40,
                right: 90,
                bottom: 60,
                left: 90
              }}
              innerRadius={0.5}
              padAngle={0.7}
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              colors={({id}) => colors[id as string] || '#000000'}
              arcLinkLabelsTextColor='#5A5A5A'
              arcLinkLabelsThickness={2}
              borderWidth={1}
              borderColor={{
                from: 'color',
                modifiers: [['darker', 0.2]]
              }}
              animate={true}
              motionConfig='gentle'
              legends={getLegends()}
              theme={pieChartTheme}
              arcLabelsTextColor={{
                from: 'color',
                modifiers: [['darker', 4]],
              }}
              arcLabelsRadiusOffset={0.55}
            />
          ) : (
            <div
              style={{
                textAlign: 'center',
                color: '#5A5A5A',
                fontSize: '16px',
                fontWeight: '600',
                paddingTop: '40px'
              }}
            >
              No data available
            </div>
          )}
      </div>
    </div>
  )
}

export default AlertsPieChart
