import axios, { AxiosError } from 'axios'
import Cookies from 'js-cookie'
import { redirectToLogin } from '../Auth'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_GATEWAY_URL,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_X_API_KEY,
  },
})

api.interceptors.request.use(
  (config) => {
    const token = Cookies.get('auth_token')

    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`
    return config
  },
  (error) => Promise.reject(error)
)

api.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    console.log({ error })
    if (error.response?.status === 401) {
      redirectToLogin()
    }
    return Promise.reject(error)
  }
)

export default api
