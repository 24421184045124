/* eslint-disable */
import React from 'react'
import {useParams} from "react-router-dom";
import PlatformLayout from '../../components/PlatformLayout'
import { Paper } from '@mui/material'
import '../../globalStyle.scss'
import { useLocation } from 'react-router-dom'
import ExceptionList from '../RulesSetting/ExceptionList';
import { rulesMapping } from '.';

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export default function EubaExceptions() {
  let query = useQuery()
  let type = query.get('type')

  const { id } = useParams<{ id: string }>();
  const ruleId = id || ''

    return (
      <PlatformLayout currentItem=''>
        <Paper
          square
          elevation={0}
          className='paperStyle'
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
          }}
        >
          <div className='ruleSettingDetailsTitle'>{rulesMapping[type as string]}</div>
          <Paper
            square
            elevation={0}
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              height: 'calc(100vh - 212px)',
              overflow: 'auto',
            }}
          >
            <ExceptionList ruleId={ruleId} />
          </Paper>
        </Paper>
      </PlatformLayout>
    )



}
