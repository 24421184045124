import { useContext, useEffect, useState } from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import {
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
} from '@mui/material'
import { useApiGet } from '../../apiCalls/useApi'
import { fetchAlertIndicators } from '../../apiCalls/alertsApi'
import PlatformLayout from '../../components/PlatformLayout'
import AlertIndicatorDialog from './AlertIndicatorDialog'
import DateFilter from '../InvestigationPage/DateFilter'
import { OrgContext } from '../../Org'
import DateRangePicker from "../../components/DateRangePicker";
import '../../globalStyle.scss'

const alertTypes = [
  'All',
  'AccessAfterDischarged',
  'AccessAfterLongPeriod',
  'AccessOutsideWorkingHours',
  'ExcessiveAccess',
  'ProviderFirstAccess',
  'AccessOutsideWorkingDays',
  'InactiveProviderAccess',
  'AccessVIP',
  'AccessSelf',
  'AccessInternal',
  'AccessFamily',
  'AccessRelatives',
  'AccessNeighbors',
]

export default function AlertIndicator({ pageName }: { pageName: string }) {
  const { org } = useContext(OrgContext)
  const appId = org.apps?.[0]?.id
  const appName = org.apps?.[0]?.name

  const { t } = useTranslation()

  const getAlertIndicators = useApiGet(fetchAlertIndicators)

  const [isLoading, setIsLoading] = useState(true)
  const [indicators, setIndicators] = useState([])
  const [selectedRowId, setSelectedRowId] = useState('')

  const defaultEndDate = dayjs().endOf('day').toDate()
  const [isCustomDate, setIsCustomDate] = useState<boolean>(false)

  const [listFromDate, setListFromDate] = useState<Date | null>(
    dayjs(defaultEndDate).subtract(3, 'months').startOf('day').toDate()
  )
  const [listToDate, setListToDate] = useState<Date | null>(defaultEndDate)

  const [selectedFilterType, setSelectedFilterType] = useState('All')

  const columns = [
    {
      field: 'indicator',
      headerName: 'Indicator',
      flex: 2.5,
    },
    {
      field: 'application',
      headerName: 'Application',
      flex: 1,
    },
    {
      field: 'date',
      headerName: 'Date',
      flex: 1,
    },
    {
      field: 'providerId',
      headerName: 'Provider Id',
      flex: 2,
    },
    {
      field: 'providerName',
      headerName: 'Provider Name',
      flex: 2,
    },
    {
      field: 'maxLevel',
      headerName: 'Risk',
      flex: 1,
    },
  ]

  const fetchIndicators = () => {
    if (!appId) return
    getAlertIndicators({
      appId,
      startDate: dayjs(listFromDate).format('YYYY-MM-DD'),
      endDate: dayjs(listToDate).format('YYYY-MM-DD'),
    }).then((res: any) => {
      if(!res || !res.data || res.error) return
      setIndicators(
        res.data.map((e: any) => ({
          ...e,
          application: appName,
          indicator: t(`indicatorTypes.${e.type}`),
          maxLevel: t(`indicatorLevels.${e.maxLevel}`),
        }))
      )
      setIsLoading(false)
    })
  }

  useEffect(() => {
    fetchIndicators()
    // eslint-disable-next-line
  }, [appId])

  function handleDateSubmitForList() {
    if (
      listFromDate &&
      dayjs(listFromDate).isValid() &&
      listToDate &&
      dayjs(listToDate).isValid()
    ) {
      const fromDateTimestamp = dayjs(listFromDate).valueOf()
      const toDateTimestamp = dayjs(listToDate).valueOf()
      if (fromDateTimestamp > toDateTimestamp) {
        return
      }
      setIsLoading(true)
      fetchIndicators()
    }
  }

  function handleFilterTypeChange(event: { target: { value: string } }) {
    setSelectedFilterType(event.target.value)
  }

  const filteredIndicators = indicators.filter((e: any) => {
    if (selectedFilterType === 'All') return true
    return e.type === selectedFilterType
  })

  return (
    <PlatformLayout currentItem={pageName}>
      <Paper
        elevation={0}
        className='paperStyle'
      >
        <div style={{ height: 'calc(100% - 84px)' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <DateFilter
              setStartDate={setListFromDate}
              setEndDate={setListToDate}
              defaultEndDate={defaultEndDate}
              submit={fetchIndicators}
              setIsCustomDate={setIsCustomDate}
              setIsLoading={setIsLoading}
              isCustomDate={isCustomDate}
            />
            {isCustomDate && (
              <DateRangePicker
                fromDate={listFromDate}
                toDate={listToDate}
                setFromDate={setListFromDate}
                setToDate={setListToDate}
                submit={handleDateSubmitForList}
              />
            )}
          </div>
          <FormControl style={{ marginTop: '8px' }}>
            <InputLabel>Indicator</InputLabel>
            <Select
              classes={{ select: 'longSelect' }}
              value={selectedFilterType}
              label='Indicator'
              onChange={handleFilterTypeChange}
              sx={{
                color: '#5B5B5B',
                height: '36px',
              }}
            >
              {alertTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {t(`indicatorTypes.${type}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <DataGrid
            disableRowSelectionOnClick
            loading={isLoading}
            columns={columns}
            rows={filteredIndicators}
            onRowClick={(params) => setSelectedRowId(params.row.id)}
            hideFooterSelectedRowCount
            slots={{
              loadingOverlay: LinearProgress,
              toolbar: GridToolbar,
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            sx={{
              border: 0,
              '& .MuiDataGrid-row:hover': {
                backgroundColor: 'rgba(235, 235, 235, 0.7)',
              },
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: '#FFFFFF',
                color: '#5B5B5B',
                fontSize: '15px',
                fontFamily: 'Segoe UI',
                borderBottom: '2px solid #BABABA',
                // borderRadius: '15px',
              },
              '& .MuiDataGrid-cell': {
                borderBottom: `1px solid #BABABA`,
                color: '#000000',
                fontSize: '12px',
                fontFamily: 'Segoe UI',
                cursor: 'pointer',
                fontWeight: '600',
              },
              '& .MuiDataGrid-columnSeparator': {
                display: 'none',
              },
              '& ::-webkit-scrollbar': {
                width: '5px',
              },
              '& ::-webkit-scrollbar-track': {
                backgroundColor: '#f1f1f1',
              },
              '& ::-webkit-scrollbar-thumb': {
                backgroundColor: '#888',
                borderRadius: '5px',
                '&:hover': {
                  backgroundColor: '#555',
                },
              },
              '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
                color: '#5B5B5B',
              },
              '& .MuiDataGrid-toolbarContainer .MuiSvgIcon-root': {
                fill: '#5B5B5B',
              },
            }}
          />
          <AlertIndicatorDialog
            alertId={selectedRowId}
            open={selectedRowId !== ''}
            onClose={() => setSelectedRowId('')}
          />
        </div>
      </Paper>
    </PlatformLayout>
  )
}
