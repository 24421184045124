import {useContext, useEffect, useState} from 'react'
import {Button, Dialog, DialogContent, LinearProgress, Typography} from '@mui/material'
import {useTranslation} from "react-i18next";
import DvrIcon from '@mui/icons-material/Dvr'
import { DataGrid } from '@mui/x-data-grid'
import { useApiGet } from '../../apiCalls/useApi'
import { fetchAlertIndicatorDetail } from '../../apiCalls/alertsApi'
import LogDetailsDialog from '../../components/LogDetailsDialog'
import {OrgContext} from "../../Org";

const dialogStyle = {
  width: '90vw',
  maxWidth: '90vw',
  maxHeight: '90vh',
  margin: 'auto',
  backgroundColor: '#FFFFFF',
  borderRadius: '10px',
}

type AlertIndicatorDialogParams = {
  alertId: string
  open: boolean
  onClose: () => void
}

export default function AlertIndicatorDialog({
  alertId,
  open,
  onClose,
}: AlertIndicatorDialogParams) {
  const {org} = useContext(OrgContext)
  const appId = org.apps?.[0]?.id

  const getAlertIndicator = useApiGet(fetchAlertIndicatorDetail)
  const [isLoading, setIsLoading] = useState(true)
  const [rows, setRows] = useState([])
  const [logDialogOpen, setLogDialogOpen] = useState(false)
  const [currentLogIds, setCurrentLogIds] = useState<string[]>([])

  const {t} = useTranslation()

  useEffect(() => {
    if (alertId === '') {
      setRows([])
      return
    }
    getAlertIndicator({
      appId,
      id: alertId,
    }).then((res: any) => {
      setIsLoading(false)
      const group = res.data
      const events = group.alertEvents.map((e: any) => ({
        ...e,
        providerId: group.providerId,
        providerName: group.providerName,
        date: group.date,
        type: t(`indicatorTypes.${group.type}`),
      }))
      setRows(events)
    })
    //   eslint-disable-next-line
  }, [alertId])

  const handleLogCountClick = (logIds: string[]) => {
    setCurrentLogIds(logIds)
    setLogDialogOpen(true)
  }

  const columns = [
    {
      field: 'date',
      headerName: 'Date',
      flex: 1,
    },
    {
      field: 'type',
      headerName: 'Indicator',
      flex: 2,
    },
    {
      field: 'providerId',
      headerName: 'Provider Id',
      flex: 1,
    },
    {
      field: 'providerName',
      headerName: 'Provider Name',
      flex: 1.5,
    },
    {
      field: 'clientId',
      headerName: 'Client Id',
      flex: 1,
    },
    {
      field: 'clientName',
      headerName: 'Client Name',
      flex: 1.5,
    },
    {
      field: 'level',
      headerName: 'Risk',
      flex: 1,
      renderCell: ({row}: any) => <>{t(`indicatorLevels.${ row.level}`)}</>,
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      renderCell: ({ row }: any) => (
        <Button
          variant='outlined'
          sx={{
            '&:hover': {
              backgroundColor: '#CCCCCC',
            },
          }}
          style={{
            padding: '10px',
            display: 'flex',
            alignItems: 'center',
            width: '135px',
            height: '27px',
            borderRadius: '8px',
            borderColor: '#000000',
          }}
          onClick={() => handleLogCountClick(row.logIds)}
        >
          <DvrIcon
            style={{
              marginRight: '5px',
              color: '#000000',
            }}
          />
          <Typography
            variant='body2'
            style={{
              fontFamily: 'Prosto One',
              fontSize: '10px',
              color: '#000000',
            }}
          >
            <strong>Risky Logs:</strong> {row.logIds.length}
          </Typography>
        </Button>
      ),
    },
  ]

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      PaperProps={{ style: dialogStyle }}
    >
      <DialogContent>
        <DataGrid
          autoHeight
          localeText={{ noRowsLabel: 'Nothing to display :)' }}
          slots={{
            loadingOverlay: LinearProgress,
          }}
          loading={isLoading}
          columns={columns}
          rows={rows}
          hideFooterSelectedRowCount
          sx={{
            border: 0,
            '& .MuiDataGrid-row:hover': {
              backgroundColor: 'rgba(235, 235, 235, 0.7)',
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#FFFFFF',
              color: '#5B5B5B',
              fontSize: '15px',
              fontFamily: 'Segoe UI',
              borderBottom: '2px solid #BABABA',
              // borderRadius: '15px',
            },
            '& .MuiDataGrid-cell': {
              borderBottom: `1px solid #BABABA`,
              color: '#000000',
              fontSize: '12px',
              fontFamily: 'Segoe UI',
              cursor: 'pointer',
              fontWeight: '600',
            },
            '& .MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            '& ::-webkit-scrollbar-track': {
              backgroundColor: '#f1f1f1',
            },
            '& ::-webkit-scrollbar-thumb': {
              backgroundColor: '#888',
              borderRadius: '5px',
              '&:hover': {
                backgroundColor: '#555',
              },
            },
            '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
              color: '#5B5B5B',
            },
            '& .MuiDataGrid-toolbarContainer .MuiSvgIcon-root': {
              fill: '#5B5B5B',
            },
          }}
        />
        <LogDetailsDialog
          appId={appId}
          open={logDialogOpen}
          onClose={() => {
            setLogDialogOpen(false)
          }}
          currentLogIds={currentLogIds}
        />
      </DialogContent>
    </Dialog>
  )
}
