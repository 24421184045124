/* eslint-disable */
import { ResponsiveBar } from '@nivo/bar'
import GraphTitle from './GraphTitle'
import { dateToMonth } from './dateHelpler'
import { graphStyle } from './commonStyle'
import { CircularProgress } from '@mui/material'

const BarChartWithMonths = ({ isLoading, data, keys, indexBy, title, totalNumber, fullScreen=false }: any) => {
  const formatMonth = (dateString: string): string => {
    const [year, month] = dateString.split('-')
    const date = new Date(Number(year), Number(month) - 1)
    return dateToMonth(date)
  }

  const maxValue = Math.max(...data.map((item: any) => item.value));
  const tickValues = maxValue < 8 ? Array.from({ length: maxValue + 1 }, (_, i) => i) : undefined;

  return (
    <div>
      <GraphTitle title={title} totalNumber={totalNumber} />
      <div style={graphStyle(fullScreen)}>
        { isLoading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <CircularProgress sx={{ color: '#A4A4A4' }} size='4rem' />
          </div>
        ) : (
          <ResponsiveBar
            data={data}
            keys={keys}
            indexBy={indexBy}
            margin={{
              top: 30,
              right: 35,
              bottom: 40,
              left: 40,
            }}
            padding={0.1}
            valueScale={{ type: 'linear' }}
            indexScale={{
              type: 'band',
              round: true,
            }}
            borderRadius={5}
            colors={['#CBE6FF']}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: '',
              legendPosition: 'middle',
              legendOffset: 32,
              format: formatMonth,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: '',
              legendPosition: 'middle',
              legendOffset: -40,
              tickValues: tickValues,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
              from: 'color',
              modifiers: [['darker', 1.6]],
            }}
            animate={true}
            motionConfig='gentle'
            theme={{
              axis: {
                ticks: {
                  text: {
                    fontSize: 12,
                    fill: '#8B8080',
                    fontFamily: 'Segoe UI',
                  },
                },
              },
              grid: {
                line: {
                  stroke: '#ddd',
                  strokeWidth: 1,
                },
              },
            }}
            fill={[
              {
                match: '*',
                id: 'gradientA',
              },
            ]}
          />
        )}
      </div>
    </div>
  )
}

export default BarChartWithMonths
