/* eslint-disable */
import '../../globalStyle.scss'
import { useTranslation } from 'react-i18next'
import DashboardDataList from './DashboardDataList'
import { dateToLocal } from './dateHelpler'
import { titleStyle } from './commonStyle'

function LatestIndicatorDataList({ isLoading, data }: any) {
  const { t } = useTranslation()

  const columns = [
    {
      field: 'date',
      headerName: 'Date',
      flex: 0.8,
      valueFormatter: (params: any) => {
        const date = new Date(params.value)
        return dateToLocal(date)
      },
    },
    { field: 'type', headerName: 'Category', flex: 1.4 },
    { field: 'providerId', headerName: 'Provider Id', flex: 0.8 },
    { field: 'name', headerName: 'Provider Name', flex: 1.5 },
    {
      field: 'riskLevel',
      headerName: 'Risk',
      flex: 0.6,
    },
  ]


  return (
    <div>
      <div style={titleStyle}>Latest 20 indicators</div>
      <DashboardDataList isLoading={isLoading} data={data} columns={columns} />
    </div>
  )
}

export default LatestIndicatorDataList
