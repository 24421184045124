import { createContext } from 'react'

interface ErrorContextType {
  isError: boolean
  setIsError: React.Dispatch<React.SetStateAction<boolean>>
}

export default createContext<ErrorContextType>({
  isError: false,
  setIsError: () => {},
})

interface SuccessContextType {
  isSuccess: boolean
  setIsSuccess: React.Dispatch<React.SetStateAction<boolean>>
}

export const SuccessContext = createContext<SuccessContextType>({
  isSuccess: false,
  setIsSuccess: () => {},
})
