import axios from "axios";
import api from './api'

export interface ApiReturnType {
  data: any
  error: Error | null
}

const apiPost = (url: string) => async (data?: any) => {
  try {
    const response = await api.post(url, data)
    return {
      data: response.data,
      error: null,
    }
  } catch (error) {
    return {
      data: null,
      error: error as Error,
    }
  }
}

export const apiPost2 = (method: string) => async (data?: any) => {
  try {
    const response = await api.post('/admin', {...data, method})
    return {
      data: response.data,
      error: null,
    }
  } catch (error) {
    return {
      data: null,
      error: error as Error,
    }
  }
}

export const fakeApiPost = (url: string, fakeData: any) => async (data?: any) => {
  console.log({ data })
  return fakeData
}

export const apiUpload = (url: string, file: File) => axios.put(url, file, {
    headers: {
      'Content-Type': file.type,
    }
  })

export default apiPost
