import { CircularProgress } from '@mui/material'

export default function IsSearchingCircleAndInfo({ info }: any) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 'calc(100vh - 400px)',
      }}
    >
      {info?.length > 0 ? info : <CircularProgress sx={{ color: '#A4A4A4' }} size='4rem' />}
    </div>
  )
}
