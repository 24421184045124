import { useContext } from 'react'
import ErrorContext, {SuccessContext} from '../ErrorContext'
import { ApiReturnType } from './apiPost'

export function useApiGet(apiCall: (data?: any) => Promise<ApiReturnType>) {
  const { setIsError } = useContext(ErrorContext)
  return async (body?: any) => {
    setIsError(false)
    const { error, data } = await apiCall(body)
    if (error) {
      setIsError(true)
      return null
    }
    return data
  }
}

export function useApiPost(apiCall: (data?: any) => Promise<ApiReturnType>) {
  const { setIsError } = useContext(ErrorContext)
  const { setIsSuccess } = useContext(SuccessContext)
  return async (body?: any) => {
    setIsError(false)
    const { error} = await apiCall(body)
    if (error) {
      setIsError(true)
      return error
    }
    setIsSuccess(true)
    return null
  }
}
