/* eslint-disable */
import { useContext, useEffect, useState } from 'react'
import PlatformLayout from '../../components/PlatformLayout'
import { Grid } from '@mui/material'
import { OrgContext } from '../../Org'
import {
  fetchDashboard,
  getLastYearEachMonthClients,
  getLastYearEachMonthProviders,
  getLastYearLogsCounts,
} from '../../apiCalls/dashboardApi'
import LoadingCircle from '../../components/LoadingCircle.tsx'
import BarChartWithMonths from '../Dashboard/BarChartsWithMonths'
import { useQuery } from 'react-query'
import { useApiGet } from '../../apiCalls/useApi'

export default function InvestigationDashboard() {
  
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [monthProviders, setMonthProviders] = useState([])
  const [monthClients, setMonthClients] = useState([])
  const [monthLogs, setMonthLogs] = useState([])
  const fetchLastYearEachMonthProviders = useApiGet(getLastYearEachMonthProviders)
  const fetchLastYearEachMonthClients = useApiGet(getLastYearEachMonthClients)
  const fetchLastYearLogsCounts = useApiGet(getLastYearLogsCounts)
  const fetchAppData = useApiGet(fetchDashboard)
  const { org } = useContext(OrgContext)
  const appId = org.apps?.[0]?.id
  const [appData, setAppData] = useState({
    providerCount: 0,
    clientCount: 0,
    logCount: 0,
  })

  useEffect(() => {
    if (!appId) {
      return
    }

    const fetchData = async () => {
      setIsLoading(true)
      const [
        eachMonthProviders ,
        eachMonthClients,
        lastYearLogsCounts ,
        dashboardCounts
      ] = await Promise.allSettled([
        fetchLastYearEachMonthProviders({ appId }),
        fetchLastYearEachMonthClients({ appId }),
        fetchLastYearLogsCounts({ appId }),
        fetchAppData()
      ])

      if (eachMonthProviders.status === 'fulfilled') {
        if(!eachMonthProviders.value || !eachMonthProviders.value?.data) return
        setMonthProviders(eachMonthProviders.value?.data)
      }else {
        return
      }

      if (eachMonthClients.status === 'fulfilled') {
        if(!eachMonthClients.value || !eachMonthClients.value?.data) return
        setMonthClients(eachMonthClients.value?.data)
      }else {
        return
      }

      if (lastYearLogsCounts.status === 'fulfilled') {
        if(lastYearLogsCounts.value?.data){
          setMonthLogs(lastYearLogsCounts.value.data.map((e: any) => ({ month: e.month, value: e.count })))
        }
      }else {
        return
      }
     if(dashboardCounts.status === 'fulfilled'){
      if(dashboardCounts.value?.data){
        setAppData(dashboardCounts.value.data[0])
      }
     }else {
      return
     }
      setIsLoading(false)
    }
    fetchData()
  }, [appId])

  if (isLoading || !appId) {
    return <LoadingCircle />
  }

  return (
    <PlatformLayout currentItem='Investigation Dashboard'>
      <div
        style={{
          width: '100%',
          height: 'calc(100vh - 120px)',
          overflow: 'auto',
          boxSizing: 'border-box',
          padding: '20px',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <BarChartWithMonths
              data={monthProviders}
              keys={['value']}
              indexBy='month'
              title='Total Providers'
              totalNumber={appData.providerCount || 0}
              fullScreen={true}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <BarChartWithMonths
              data={monthClients}
              keys={['value']}
              indexBy='month'
              title='Total Clients'
              totalNumber={appData.clientCount || 0}
              fullScreen={true}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <BarChartWithMonths
              data={monthLogs}
              keys={['value']}
              indexBy='month'
              title='Total Log Events'
              totalNumber={appData.logCount || 0}
              fullScreen={true}
            />
          </Grid>
        </Grid>
      </div>
    </PlatformLayout>
  )
}
