import { createContext } from 'react'

interface AppContextType {
  id: string
  name: string
}

export interface OrgContextType {
  org: {
    id: string
    name: string
    apps: AppContextType[]
  }
  setOrg: any
}

export const OrgContext = createContext<OrgContextType>({
  org: {
    id: '',
    name: '',
    apps: [],
  },
  setOrg: () => {},
})
