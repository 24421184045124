/* eslint-disable */
import {useContext, useEffect, useState} from 'react'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import '../../globalStyle.scss'
import {Grid} from '@mui/material'
import PlatformLayout from '../../components/PlatformLayout'
import BarChartWithMonths from './BarChartsWithMonths'
import AlertsBarChart from './AlertsBarChart'
import AlertsPieChart from './AlertsPieChart'
import HorizontalBarChart from './HorizontalBarChart'
import LatestAlertDataList from './LatestAlertDataList'
import LatestIndicatorDataList from './LatestIndicatorDataList'
import {
  fetchDashboard,
  getAlertsSummary,
  getIndicatorCounts,
  getLastYearEachMonthAlerts,
  getLastYearEachMonthClients,
  getLastYearEachMonthProviders,
  getLastYearLogsCounts,
  getLatestTwentyAlerts,
  getLatestTwentyIndicators,
  getOpenOrClosedAlertsSummary,
} from '../../apiCalls/dashboardApi'
import { OrgContext } from '../../Org'
import { processAlertsData, processAlertsSummary, processIndicatorData } from './processDataHelpMethods'
import LoadingCircle from '../../components/LoadingCircle.tsx'

export type OpenOrClosedAlertSummary = {
  total: number
  dataForPie: { id: string; label: string; value: number }[]
}

export type IndicatorInfo = {
  indicator: string
  count: number
}

export type Indicators = {
  totalIndicator: number
  dataForChart: IndicatorInfo[]
}
export default function Dashboard() {
  const [appData, setAppData] = useState({
    providerCount: 0,
    clientCount: 0,
    logCount: 0,
  })
  const [isAppDataLoading, setIsAppDataLoading] = useState(true)
  const [monthProviders, setMonthProviders] = useState([])
  const [isMonthProvidersLoading, setIsMonthProvidersLoading] = useState(true)
  const [monthClients, setMonthClients] = useState([])
  const [isMonthClientsLoading, setIsMonthClientsLoading] = useState(true)
  const [monthAlerts, setMonthAlerts] = useState([])
  const [isMonthAlertsLoading, setIsMonthAlertsLoading] = useState(true)
  const [monthLogs, setMonthLogs] = useState([])
  const [isMonthLogsLoading, setIsMonthLogsLoading] = useState(true)
  const [alertsSummary, setAlertsSummary] = useState({
    total: 0,
    high: 0,
    medium: 0,
    low: 0,
  })
  const [isAlertsSummaryLoading, setIsAlertsSummaryLoading] = useState(true)
  const [openAlertsSummary, setOpenAlertsSummary] = useState<OpenOrClosedAlertSummary>({
    total: 0,
    dataForPie: [],
  })
  const [isOpenedAlertsSummaryLoading, setIsOpenedAlertsSummaryLoading] = useState(true)
  const [closedAlertsSummary, setClosedAlertsSummary] = useState<OpenOrClosedAlertSummary>({
    total: 0,
    dataForPie: [],
  })
  const [isClosedAlertsSummaryLoading, setIsClosedAlertsSummaryLoading] = useState(true)
  const [indicatorData, setIndicatorData] = useState<Indicators>({
    totalIndicator: 0,
    dataForChart: [],
  })
  const [isIndicatorDataLoading, setIsIndicatorDataLoading] = useState(true)
  const [latestTwentyAlerts, setLatestTwentyAlerts] = useState([])
  const [isLatestTwentyAlertsLoading, setIsLatestTwentyAlertsLoading] = useState(true)
  const [latestTwentyIndicators, setLatestTwentyIndicators] = useState([])
  const [isLatestTwentyIndicatorsLoading, setIsLatestTwentyIndicatorsLoading] = useState(true)
  const { org } = useContext(OrgContext)
  const appId = org.apps?.[0]?.id

  useEffect(() => {
    if (!appId) {
      return
    }

    const fetchData = async () => {
      fetchDashboard().then(({ data, error }) => {
        if (error) {
          return
        }
        setAppData(data.data[0])
        setIsAppDataLoading(false)
      })
      getLastYearEachMonthProviders({ appId }).then(({ data, error }) => {
        if (error) {
          return
        }
        setMonthProviders(data.data)
        setIsMonthProvidersLoading(false)
      })
      getLastYearEachMonthClients({ appId }).then(({ data, error }) => {
        if (error) {
          return
        }
        setMonthClients(data?.data)
        setIsMonthClientsLoading(false)
      })
      getLastYearLogsCounts({ appId }).then(({ data, error }) => {
        if (error) {
          return
        }
        setMonthLogs(
          data.data.map((e: any) => ({
            month: e.month,
            value: e.count,
          }))
        )
        setIsMonthLogsLoading(false)
      })
      getOpenOrClosedAlertsSummary({
        appId,
        status: 'OPEN',
      }).then(({ data, error }) => {
        if (error) {
          return
        }
        setOpenAlertsSummary(processAlertsData(data.data))
        setIsOpenedAlertsSummaryLoading(false)
      })
      await new Promise((resolve) => setTimeout(resolve, 500))
      getOpenOrClosedAlertsSummary({
        appId,
        status: 'CLOSED',
      }).then(({ data, error }) => {
        if (error) {
          return
        }
        setClosedAlertsSummary(processAlertsData(data.data))
        setIsClosedAlertsSummaryLoading(false)
      })
      getLastYearEachMonthAlerts({ appId }).then(({ data, error }) => {
        if (error) {
          return
        }
        setMonthAlerts(data?.data)
        setIsMonthAlertsLoading(false)
      })
      getAlertsSummary({ appId }).then(({ data, error }) => {
        if (error) {
          return
        }
        setAlertsSummary(processAlertsSummary(data.data))
        setIsAlertsSummaryLoading(false)
      })
      await new Promise((resolve) => setTimeout(resolve, 500))
      getIndicatorCounts({ appId }).then(({ data, error }) => {
        if (error) {
          return
        }
        setIndicatorData(processIndicatorData(data.data))
        setIsIndicatorDataLoading(false)
      })
      getLatestTwentyAlerts({ appId }).then(({ data, error }) => {
        if (error) {
          return
        }
        setLatestTwentyAlerts(data.data)
        setIsLatestTwentyAlertsLoading(false)
      })
      getLatestTwentyIndicators({ appId }).then(({ data, error }) => {

        if (error) {
          return
        }
        setLatestTwentyIndicators(data.data)
        setIsLatestTwentyIndicatorsLoading(false)
      })
    }
    fetchData()
  }, [appId])

  if (!appId || isAppDataLoading) {
    return (
      <LoadingCircle/>
    )
  }

  return (
    <PlatformLayout currentItem='Dashboard'>
      <div
        style={{
          width: '100%',
          height: 'calc(100vh - 120px)',
          overflow: 'auto',
          boxSizing: 'border-box',
          padding: '20px',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <BarChartWithMonths
              isLoading={isMonthProvidersLoading}
              data={monthProviders}
              keys={['value']}
              indexBy='month'
              title='Total Providers'
              totalNumber={appData.providerCount || 0}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <BarChartWithMonths
              isLoading={isMonthClientsLoading}
              data={monthClients}
              keys={['value']}
              indexBy='month'
              title='Total Clients'
              totalNumber={appData.clientCount || 0}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <BarChartWithMonths
              isLoading={isMonthLogsLoading}
              data={monthLogs}
              keys={['value']}
              indexBy='month'
              title='Total Log Events'
              totalNumber={appData.logCount || 0}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <AlertsBarChart
              isLoading={isMonthAlertsLoading || isAlertsSummaryLoading}
              data={monthAlerts}
              indexBy='month'
              title='Total Alerts'
              totalNumber={alertsSummary.total}
              subNumbers={{
                high: alertsSummary.high,
                medium: alertsSummary.medium,
                low: alertsSummary.low,
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <AlertsPieChart
              isLoading={isOpenedAlertsSummaryLoading}
              data={openAlertsSummary.dataForPie}
              title='Total Open Alerts'
              totalNumber={openAlertsSummary.total}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <AlertsPieChart
              isLoading={isClosedAlertsSummaryLoading}
              data={closedAlertsSummary.dataForPie}
              title='Total Closed Alerts'
              totalNumber={closedAlertsSummary.total}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <HorizontalBarChart
              isLoading={isIndicatorDataLoading}
              data={indicatorData.dataForChart}
              title='Total Indicators'
              totalNumber={indicatorData.totalIndicator}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <LatestAlertDataList
              isLoading={isLatestTwentyAlertsLoading}
              data={latestTwentyAlerts}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <LatestIndicatorDataList
              isLoading={isLatestTwentyIndicatorsLoading}
              data={latestTwentyIndicators}
            />
          </Grid>
        </Grid>
      </div>
    </PlatformLayout>
  )
}
