import React, { useContext, useEffect } from 'react'
import Cookies from 'js-cookie'
import { AuthContext, getAuthData, redirectToLogin } from '../Auth'

export default function PrivateRoute({ children }: { children: React.ReactElement }) {
  const { auth, setAuth } = useContext(AuthContext)
  useEffect(() => {
    const token = Cookies.get('auth_token') || ''
    if (!token) {
      redirectToLogin()
      return
    }
    const authData = getAuthData(token)
    setAuth(authData)
  }, [setAuth])

  if (!auth) {
    const token = Cookies.get('auth_token')
    if (!token) {
      redirectToLogin()
    }

    return <h1>Loading...</h1>
  }
  return children
}
