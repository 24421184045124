/* eslint-disable */
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import { Dialog, DialogTitle, DialogContent, LinearProgress } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { LogDetailsDialogProps } from '../utils/interfaces'
import { fetchAccessLogs } from '../apiCalls/alertsApi'
import dayjs from 'dayjs'

function LogDetailsDialog({ appId, open, onClose, currentLogIds }: LogDetailsDialogProps) {
  const [logs, setLogs] = useState([])

  const {
    data: logData,
    isLoading,
    error,
  } = useQuery([currentLogIds], () => fetchAccessLogs({ appId, logIds: currentLogIds }))

  useEffect(() => {
    const initSearchLogs = async () => {
      setLogs([])
      if (logData?.data) {
        const { data } = logData
        setLogs(data.data)
      }
    }
    initSearchLogs()
  }, [error, logData])

  const columns = [
    {
      field: 'datetime',
      headerName: 'Time',
      flex: 0.7,
      renderCell: (params: any) => <div>{dayjs(params.value).format('YYYY-MM-DD HH:mm:ss')}</div>,
    },
    { field: 'eventType', headerName: 'Event Type', flex: 0.6 },
    { field: 'clientId', headerName: 'Client Id', flex: 0.6 },
    { field: 'clientName', headerName: 'Client Name', flex: 0.7 },
    { field: 'clientService', headerName: 'Client Service', flex: 2 },
    { field: 'providerId', headerName: 'Provider Id', flex: 0.7 },
    { field: 'providerName', headerName: 'Provider Name', flex: 0.8 },
    { field: 'providerService', headerName: 'Provider Service', flex: 1 },
  ]

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          maxWidth: '95vw',
        },
      }}
    >
      <DialogTitle style={{ color: '#000000', fontSize: '16px', fontWeight: 'bold' }}>
        Log Details
      </DialogTitle>
      <DialogContent sx={{ maxHeight: '70vh' }}>
        <DataGrid
          autoHeight
          hideFooter
          slots={{
            loadingOverlay: LinearProgress,
          }}
          loading={isLoading}
          columns={columns}
          rows={logs}
          localeText={{ noRowsLabel: 'Nothing to display :)' }}
          sx={{
            border: 'none',
            '& .MuiDataGrid-row:hover': {
              backgroundColor: 'rgba(235, 235, 235, 0.7)',
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#ECECEC',
              color: '#5B5B5B',
              fontSize: '13px',
              borderBottom: '2px solid #BABABA',
            },
            '& .MuiDataGrid-cell': {
              borderBottom: `1px solid #BABABA`,
              color: '#000000',
              fontSize: '10px',
              cursor: 'pointer',
              fontWeight: '500',
            },
            '& .MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            '& ::-webkit-scrollbar': {
              width: '5px',
            },
            '& ::-webkit-scrollbar-track': {
              backgroundColor: '#f1f1f1',
            },
            '& ::-webkit-scrollbar-thumb': {
              backgroundColor: '#888',
              borderRadius: '5px',
              '&:hover': {
                backgroundColor: '#555',
              },
            },
            minHeight: '300px',
            width: '90vw',
          }}
        />
      </DialogContent>
    </Dialog>
  )
}

export default LogDetailsDialog
