/* eslint-disable */
import { IndicatorInfo, Indicators } from "."

export const processAlertsSummary = (data: any) => {
    let total = 0
    let high = 0
    let medium = 0
    let low = 0
    data.forEach((item: any) => {
      if (item.riskLevel === 'HIGH') {
        high = item.count
      }
      if (item.riskLevel === 'MEDIUM') {
        medium = item.count
      }
      if (item.riskLevel === 'LOW') {
        low = item.count
      }
      total += item.count
    })

    return {
      total,
      high,
      medium,
      low,
    }
  }

  export const processAlertsData = (data: any) => {
    let dataForPie = [
      {
        id: 'High',
        label: 'High',
        value: data?.high || 0,
      },
      {
        id: 'Medium',
        label: 'Medium',
        value: data?.medium || 0,
      },
      {
        id: 'Low',
        label: 'Low',
        value: data?.low || 0,
      },
    ]
    return {
      total: data?.total || 0,
      dataForPie,
    }
  }

  export const processIndicatorData = (data: IndicatorInfo[]): Indicators => {
    let totalIndicator = 0
    const indicatorMap: { [key: string]: string } = {
      AccessOutsideWorkingDays: 'Provider access clients on non-working days',
      AccessOutsideWorkingHours: 'Provider access a client out of hours',
      AccessAfterLongPeriod: 'Provider access a client after long period',
      ProviderFirstAccess: 'Provider fist time access a client',
      ExcessiveAccess: 'Provider access more clients than average',
      AccessAfterDischarged: 'Provider access an inactive client',
    }

    const result = data.map((item: IndicatorInfo) => {
      totalIndicator += item.count
      return {
        indicator: indicatorMap[item.indicator] || item.indicator,
        count: item.count,
      }
    })
    return {
      totalIndicator,
      dataForChart: result,
    }
  }