/* eslint-disable */
import {Button, Fab, LinearProgress, Paper, Switch, TextField, Typography,} from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import {
  createMatchedProviderClient,
  getMatchedProviderClient,
  updateMatchedProviderClient,
} from '../../apiCalls/rulesApi'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import '../../globalStyle.scss'
import SearchModal from './SearchModal'
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined'
import { OrgContext } from '../../Org'
import { useApiGet, useApiPost } from '../../apiCalls/useApi'
import { ConfirmContext } from '../../Confirm'

type PersonInfoType = {
  id: string
  name: string
}

export default function AddProviderAndClient() {
  const { org } = useContext(OrgContext)
  const appId = org.apps?.[0]?.id

  const [providerClientList, setProviderClientList] = useState([])
  const [open, setOpen] = useState(false)
  const [type, setType] = useState<string | null | undefined>()
  const [chosenProvider, setChosenProvider] = useState<PersonInfoType | null>(null)
  const [chosenClient, setChosenClient] = useState<PersonInfoType | null>(null)
  const [dataLoading, setDataLoading] = useState<boolean>(false)
  const [switchChecked, setSwitchChecked] = useState<boolean>(false)
  const [row, setRow] = useState<any>({})

  const fetchMatched = useApiGet(getMatchedProviderClient)
  const callCreateApi = useApiPost(createMatchedProviderClient)
  const callUpdateApi = useApiPost(updateMatchedProviderClient)

  const { openConfirm, setConfirmSettings } = useContext(ConfirmContext)

  const reload = async () => {
    setDataLoading(true)
    const res = await fetchMatched({appId})
    if(!res || !res.data || res.error) return
    setProviderClientList(res.data)
    setDataLoading(false)
    setChosenClient(null)
    setChosenProvider(null)
    setRow({})
    setSwitchChecked(false)
  }

  const handleSwitchChange = async (event: any, row: any) => {
    setSwitchChecked(event.target.checked)
    setRow(row)
    openConfirm()
  }

  async function handleClickConfirm() {
    const changeTo = switchChecked ? 'ON' : 'OFF'
    const err = await callUpdateApi({
      app_id: appId,
      id: row.id,
      status: changeTo,
    })

    if (err) return
    reload()
  }

  const columnForProviderAndClientList = [
    {
      field: 'provider_id',
      headerName: 'Provider Id',
      flex: 1,
    },
    {
      field: 'provider_name',
      headerName: 'Provider Name',
      flex: 1,
    },
    {
      field: 'client_id',
      headerName: 'Client Id',
      flex: 1,
    },
    {
      field: 'client_name',
      headerName: 'Client Name',
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params: any) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant='body2' color='textSecondary' style={{ marginRight: 8 }}>
            Off
          </Typography>
          <Switch
            checked={params.value === 'ON'}
            onChange={(event: any) => {
              handleSwitchChange(event, params.row)
            }}
          />
          <Typography variant='body2' color='textSecondary' style={{ marginLeft: 8 }}>
            On
          </Typography>
        </div>
      ),
    },
  ]

  useEffect(() => {
    reload()
  }, [])

  useEffect(() => {
    setConfirmSettings({
      text: 'Toggle the status?',
      confirmCallback: handleClickConfirm,
    })
  }, [row]);

  const pressChooseButton = (providerOrClient: string) => {
    setOpen(true)
    setType(providerOrClient)
  }

  const onSubmit = async () => {
    if (!chosenProvider || !chosenClient) return
    const err: any = await callCreateApi({
      app_id: appId,
      providerId: chosenProvider.id,
      clientId: chosenClient.id,
    })

    if (err) return
    reload()
  }
  return (
    <Paper
      square
      elevation={0}
      style={{
        flex: 1,
        width: '99.5%',
        border: '2px solid gray',
        borderRadius: '6px',
        marginTop: '8px',
      }}
    >
      <div className='addProviderAndClientTitle'>Matched Provider/Client</div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '10px',
          marginTop: '20px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Fab
            variant='extended'
            sx={{
              backgroundColor: '#1876D1',
              color: '#ffffff',
              '&:hover': {
                backgroundColor: '#1d88f3',
              },
            }}
            onClick={() => {
              pressChooseButton('Provider')
            }}
          >
            <PersonSearchOutlinedIcon />
            Choose Provider
          </Fab>
          <TextField
            id='standard-basic'
            label='Provider'
            variant='outlined'
            value={chosenProvider ? `${chosenProvider.name} (${chosenProvider.id})` : ''}
            disabled
            sx={{
              marginLeft: '10px',
              width: '300px',
            }}
          />
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Fab
            variant='extended'
            sx={{
              backgroundColor: '#1876D1',
              color: '#ffffff',
              '&:hover': {
                backgroundColor: '#1d88f3',
              },
              marginLeft: '10px',
            }}
            onClick={() => {
              pressChooseButton('Client')
            }}
          >
            <PersonSearchOutlinedIcon />
            Choose Client
          </Fab>

          <TextField
            id='standard-basic'
            label='Client'
            variant='outlined'
            value={chosenClient ? `${chosenClient.name} (${chosenClient.id})` : ''}
            disabled
            sx={{
              marginLeft: '10px',
              width: '300px',
            }}
          />
        </div>

        <div style={{ marginLeft: '10px' }}>
          <Button
            variant='contained'
            size='large'
            onClick={onSubmit}
          >
            Add
          </Button>
        </div>
      </div>

      <Paper
        elevation={0}
        style={{
          padding: '10px',
          // margin: '15px',
          height: 'calc(100vh - 390px)',
        }}
      >
        <DataGrid
          getRowId={(row: any) => row.id}
          loading={dataLoading}
          slots={{
            toolbar: GridToolbar,
            loadingOverlay: LinearProgress,
          }}
          rows={providerClientList || []}
          // hideFooter
          columns={columnForProviderAndClientList}
          sx={{
            border: 0,
            '& .MuiDataGrid-row:hover': {
              backgroundColor: 'rgba(235, 235, 235, 0.7)',
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#FFFFFF',
              color: '#5B5B5B',
              fontSize: '15px',
              fontFamily: 'Segoe UI',
              borderBottom: '2px solid #BABABA',
              // borderRadius: '15px',
            },
            '& .MuiDataGrid-cell': {
              borderBottom: `1px solid #BABABA`,
              color: '#000000',
              fontSize: '12px',
              fontFamily: 'Segoe UI',
              cursor: 'pointer',
              fontWeight: '600',
            },
            '& .MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            '& ::-webkit-scrollbar': {
              width: '5px',
            },
            '& ::-webkit-scrollbar-track': {
              backgroundColor: '#f1f1f1',
            },
            '& ::-webkit-scrollbar-thumb': {
              backgroundColor: '#888',
              borderRadius: '5px',
              '&:hover': {
                backgroundColor: '#555',
              },
            },
            '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
              color: '#5B5B5B',
            },
            '& .MuiDataGrid-toolbarContainer .MuiSvgIcon-root': {
              fill: '#5B5B5B',
            },
          }}
        />
      </Paper>
      <SearchModal
        open={open}
        setOpen={setOpen}
        type={type}
        setChosenProvider={setChosenProvider}
        setChosenClient={setChosenClient}
      />
    </Paper>
  )
}
