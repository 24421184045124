import {apiPost2} from './apiPost'

export const fetchDashboard = apiPost2('get-dashboard')

export const getLastYearEachMonthProviders = apiPost2('last-year-each-month-providers')

export const getLastYearEachMonthClients = apiPost2('last-year-each-month-clients')

export const getLastYearEachMonthAlerts = apiPost2('last-year-each-month-alerts')

export const getAlertsSummary = apiPost2('get-alerts-summary')

export const getOpenOrClosedAlertsSummary = apiPost2('get-open-or-closed-alerts-summary')

export const getIndicatorCounts = apiPost2('get-indicator-counts')

export const getLatestTwentyAlerts = apiPost2('get-latest-twenty-alerts')

export const getLatestTwentyIndicators = apiPost2('get-latest-twenty-indicators')

export const getLastYearLogsCounts = apiPost2('get-last-year-logs-counts')
