/* eslint-disable */
import { useEffect, useState } from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { LinearProgress, Paper, Skeleton } from '@mui/material'
import { ResponseData } from '../../utils/interfaces'
import AlertDetailDialog from './AlertDetailDialog'
import '../../globalStyle.scss'
import { useTranslation } from 'react-i18next'

function AlertPageDataList({ appId, isLoading, data, reload }: any) {
  const { t } = useTranslation()

  const [selectedRow, setSelectedRow] = useState<ResponseData | null>(null)

  const columns = [
    { field: 'appName', headerName: 'Application', flex: 1 },
    { field: 'date', headerName: 'Date', flex: 1 },
    { field: 'providerId', headerName: 'Provider Id', flex: 1 },
    { field: 'providerName', headerName: 'Provider Name', flex: 2 },
    {
      field: 'riskLevel',
      headerName: 'Risk',
      flex: 1,
    },
    {
      field: 'statusText',
      headerName: 'Status',
      flex: 1,
    },
    {
      field: 'confirmationText',
      headerName: 'Confirmation',
      flex: 1,
    },
  ]

  return (
    <>
      <DataGrid
        loading={isLoading}
        hideFooterSelectedRowCount
        slots={{
          loadingOverlay: LinearProgress,
          toolbar: GridToolbar,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        columns={columns}
        onRowClick={(params) => setSelectedRow(params.row)}
        rows={data?.data || []}
        sx={{
          border: 0,
          '& .MuiDataGrid-row:hover': {
            backgroundColor: 'rgba(235, 235, 235, 0.7)',
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#FFFFFF',
            color: '#5B5B5B',
            fontSize: '15px',
            fontFamily: 'Segoe UI',
            borderBottom: '2px solid #BABABA',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: `1px solid #BABABA`,
            fontSize: '12px',
            cursor: 'pointer',
            fontFamily: 'Segoe UI',
            fontWeight: '600',
          },
          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& ::-webkit-scrollbar': {
            width: '5px',
          },
          '& ::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1',
          },
          '& ::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '5px',
            '&:hover': {
              backgroundColor: '#555',
            },
          },
          '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
            color: '#5B5B5B',
          },
          '& .MuiDataGrid-toolbarContainer .MuiSvgIcon-root': {
            fill: '#5B5B5B',
          },
        }}
      />

      <AlertDetailDialog
        appId={appId}
        open={selectedRow !== null}
        onClose={() => setSelectedRow(null)}
        row={selectedRow}
        reload={reload}
      />
    </>
  )
}

export default AlertPageDataList
