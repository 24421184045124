/* eslint-disable */
import React from 'react'
import { ResponsiveBar } from '@nivo/bar'
import GraphTitle from './GraphTitle'
import { graphStyle } from './commonStyle'
import {CircularProgress} from "@mui/material";

const HorizontalBarChart = ({ isLoading, data, title, totalNumber }: any) => {
  return (
    <div>
      <GraphTitle title={title} totalNumber={totalNumber} />
      <div style={graphStyle(false)}>
        {isLoading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <CircularProgress sx={{ color: '#A4A4A4' }} size='4rem' />
          </div>
        ) : (
          <ResponsiveBar
            data={data || []}
            keys={['count']}
            indexBy='indicator'
            layout='horizontal'
            margin={{
              top: 30,
              right: 35,
              bottom: 40,
              left: 235,
            }}
            padding={0.25}
            valueScale={{ type: 'linear' }}
            indexScale={{
              type: 'band',
              round: true,
            }}
            borderRadius={5}
            colors={['#CBE6FF']}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: '',
              legendPosition: 'middle',
              legendOffset: 32,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: '',
              legendPosition: 'middle',
              legendOffset: -40,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
              from: 'color',
              modifiers: [['darker', 1.6]],
            }}
            animate={true}
            motionConfig='gentle'
            theme={{
              axis: {
                ticks: {
                  text: {
                    fontSize: '11px',
                    fill: '#8B8080',
                    fontFamily: 'Segoe UI',
                  },
                },
              },
              grid: {
                line: {
                  stroke: '#ddd',
                  strokeWidth: 1,
                },
              },
            }}
          />
        )}
      </div>
    </div>
  )
}

export default HorizontalBarChart
