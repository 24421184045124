/* eslint-disable */
import { CircularProgress } from '@mui/material'

export default function InvestigationMessage({
  message,
  isSearching,
  type,
  data,
  setIsSearching,
  setCanPersonListOpen,
  setSearchedPersonData,
  paginationModel,
  setPaginationModel,
  total,
  allPersonByPaginationIsLoading
}: any) {
  return (
    <div
      style={{
        fontSize: '20px',
        fontFamily: 'Segoe UI',
        color: '#A4A4A4',
        height: 'calc(100vh - 190px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {isSearching ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress sx={{ color: '#A4A4A4' }} />
        </div>
      ) : ''}
      {/* {message} */}
    </div>
  )
}
