/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Chart } from 'react-google-charts'
import '../../globalStyle.scss'
import IsSearchingCircleAndInfo from './IsSearchingCircleAndInfo'

export default function AccessTimeline({ timelineData, type, isLoading }: any) {
  const [data, setData] = useState<any>([])
  useEffect(() => {
    let tempData = [
      [
        { type: 'string', id: type === 'Provider' ? 'Client' : 'Provider' },
        { type: 'string', id: 'dummy bar label' },
        { type: 'string', role: 'tooltip' },
        { type: 'date', id: 'Start' },
        { type: 'date', id: 'End' },
      ],
      ...timelineData.flatMap((item: any) =>
        item.filteredLogs.map((log: any) => [
          type === 'Provider'
            ? `${item.clientId} (${item.clientName})`
            : `${item.providerId} (${item.providerName})`,
          '',
          `<div style="padding:10px;min-width:220px;min-height:140px;display:flex;flex-direction:column;justify-content:space-between">
            <div style="display:flex;align-items:center;padding:5px">
              <div style="font-size:16px;font-weight:bold">Provider ID: </div>
              <div style="font-size:14px;margin-left:3px">${item.providerId}</div>
            </div>
            <div style="display:flex;align-items:center;padding:5px">
              <div style="font-size:16px;font-weight:bold">Provider Name: </div>
              <div style="font-size:14px;margin-left:3px"> ${log.providerName}</div>
            </div>
            <div style="display:flex;align-items:center;padding:5px">
              <div style="font-size:16px;font-weight:bold">Client ID: </div>
              <div style="font-size:14px;margin-left:3px">${item.clientId}</div>
            </div>
            <div style="display:flex;align-items:center;padding:5px">
              <div style="font-size:16px;font-weight:bold">Client Name: </div>
              <div style="font-size:14px;margin-left:3px"> ${log.clientName}</div>
            </div>
            <div style="display:flex;align-items:center;padding:5px">
              <div style="font-size:16px;font-weight:bold">Type: </div>
              <div style="font-size:14px;margin-left:3px"> ${log.eventType}</div>
            </div>
            <div style="display:flex;align-items:center;padding:5px">
              <div style="font-size:16px;font-weight:bold">Date: </div>
              <div style="font-size:14px;margin-left:3px"> ${new Date(log.datetime).toLocaleString(
                'en-US',
                {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                }
              )}</div>
            </div>  
          </div>`,
          new Date(log.datetime),
          new Date(log.datetime),
        ])
      ),
    ]
    setData(tempData)

    return () => {
      setData([])
    }
  }, [timelineData])

  return (
    <div className='graphBody'>
      <div
        style={{
          width: '100%',
          height: '98%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          boxSizing: 'border-box',
          padding: '10px',
        }}
      >
        {isLoading && <IsSearchingCircleAndInfo info='' />}
        {timelineData.length === 0 && !isLoading && <IsSearchingCircleAndInfo info='no data' />}
        {data && !isLoading && data.length > 0 && timelineData.length > 0 && (
          <Chart
            width={'100%'}
            height={'99%'}
            chartType='Timeline'
            loader={<div>Loading Timeline...</div>}
            data={data}
            options={{
              allowHtml: true,
              tooltip: { isHtml: true },
              timeline: {
                colorByRowLabel: true,
                singleColor: '#858585',
              },
              explorer: {
                axis: 'horizontal',
                keepInBounds: true,
                maxZoomIn: 0.1,
              },
              hAxis: {
                format: 'MM/dd/yyyy',
                gridlines: { count: -1 },
                minorGridlines: { count: 15 },
              },
            }}
          />
        )}
      </div>
    </div>
  )
}
