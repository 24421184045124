import { createContext } from 'react'

export type ConfirmSettingsType = {
  text: string
  confirmCallback: (args?: any) => Promise<void>
}

export interface ConfirmContextType {
  confirmSettings: ConfirmSettingsType
  setConfirmSettings: ({ text, confirmCallback }: ConfirmSettingsType) => void
  isConfirmOpen: boolean
  openConfirm: () => void
  closeConfirm: () => void
}

export const ConfirmContext = createContext<ConfirmContextType>({
  confirmSettings: {
    text: '',
    confirmCallback: () => Promise.resolve(),
  },
  setConfirmSettings: () => {},
  isConfirmOpen: false,
  openConfirm: () => {},
  closeConfirm: () => {},
})
