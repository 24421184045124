import { CircularProgress } from '@mui/material'
import PlatformLayout from '../PlatformLayout'

export default function LoadingCircle() {
  return (
    <PlatformLayout currentItem='Dashboard'>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress sx={{ color: '#A4A4A4' }} size='4rem' />
          <div
            style={{
              fontSize: '18px',
              color: '#A4A4A4',
              marginTop: '5px',
              fontWeight: '600',
            }}
          >
            Loading...
          </div>
        </div>
      </PlatformLayout>
  )
}
