import {apiPost2} from './apiPost'

export const getRules = apiPost2('get-rules')

export const getEubaRules = apiPost2('get-euba-rules')

export const updateRules = apiPost2('update-rule')

export const getMatchedProviderClient = apiPost2('get-matched')

export const createMatchedProviderClient = apiPost2('create-matched')

export const updateMatchedProviderClient = apiPost2('update-matched')

export const getRuleTargets = apiPost2('get-rule-targets')
export const createRuleTarget = apiPost2('create-rule-target')
export const updateRuleTarget = apiPost2('update-rule-target')
export const deleteRuleTarget = apiPost2('delete-rule-target')
