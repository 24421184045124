/* eslint-disable */
import { Outlet } from 'react-router-dom'
import HelpPageLayout from './HelpPageLayout'

export default function HelpPage() {
  return (
    <HelpPageLayout>
      <div
        style={{
          width: '100%',
          padding: '20px',
          boxSizing: 'border-box',
          height: 'calc(100vh - 107px)',
          overflow: 'auto',
        }}
      >
        <Outlet />
      </div>
    </HelpPageLayout>
  )
}
