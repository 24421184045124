/* eslint-disable */

import '../../globalStyle.scss'
import { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import PersonInfo from './PersonInfo'
import { fetchProvider } from '../../apiCalls/alertsApi'
import { OrgContext } from '../../Org'
import { useApiGet } from '../../apiCalls/useApi'
import InvestigationContent from './InvestigationContent'

export default function ProviderInvestigation() {
  const { org } = useContext(OrgContext)
  const appId = org.apps?.[0]?.id
  const location = useLocation()
  const { searchID } = location.state || {}
  const [personInfo, setPersonInfo] = useState({
    orgId: '',
    appId: '',
    id: '',
    name: '',
    service: '',
    firstAccessTime: '',
    lastAccessTime: '',
  })
  const [isPersonInfoLoading, setIsPersonInfoLoading] = useState<boolean>(false)
  const getPersonInfo = useApiGet(fetchProvider)

  useEffect(() => {
    if (searchID) {
      setIsPersonInfoLoading(true)
      getPersonInfo({
        appId,
        providerId: searchID,
      }).then((res: any) => {
        if (res && res.data && !res.error) {
          setPersonInfo(res.data)
          setIsPersonInfoLoading(false)
        }
      })
    }
  }, [searchID])

  return (
    <div className='investigationContent'>
      <PersonInfo info={personInfo} isLoading={isPersonInfoLoading} type='Provider' />
      <InvestigationContent type='Provider' searchID={searchID} />
    </div>
  )
}
