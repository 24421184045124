/* eslint-disable */
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { useEffect, useRef, useState } from 'react'
import {
  MenuItem,
  ButtonGroup,
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@mui/material'
import '../../globalStyle.scss'
import dayjs from 'dayjs'
import Menu from '@mui/material/Menu'

type DateFilterProps = {
  setStartDate: (value: Date) => void
  setEndDate: (value: Date) => void
  defaultEndDate: Date
  submit: () => void
  setIsCustomDate: (value: boolean) => void
  setIsLoading: (value: boolean) => void
  isCustomDate: boolean
}

export default function DateFilter({
  setStartDate,
  setEndDate,
  defaultEndDate,
  submit,
  setIsCustomDate,
  setIsLoading,
  isCustomDate,
}: DateFilterProps) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [value, setValue] = useState<string>('Last 3 months')
  const [apply, setApply] = useState(false)

  const getIntervalFromValue = (val: string, defaultEndDate: Date) => {
    const endDate = dayjs(defaultEndDate)
    if (val === 'Custom time interval') {
      setIsCustomDate(true)
      return endDate.subtract(3, 'months').startOf('day').toDate()
    }
    setIsCustomDate(false)
    switch (val) {
      case 'Last 3 months':
        return endDate.subtract(3, 'months').startOf('day').toDate()
      case 'Last day':
        return endDate.startOf('day').toDate()
      case 'Last 7 days':
        return endDate.subtract(7, 'days').startOf('day').toDate()
      case 'Last 30 days':
        return endDate.subtract(30, 'days').startOf('day').toDate()
      case 'Last year':
        return endDate.subtract(1, 'year').startOf('day').toDate()
      default:
        return endDate.subtract(1, 'year').startOf('day').toDate()
    }
  }

  const handleRadioChange = (event: any) => {
    setIsCustomDate(false)
    setValue(event.target.value)
  }

  const handleApply = () => {
    if (value !== 'Custom time interval') {
      setIsLoading(true)
    }
    setAnchorEl(null)
    setValue(value)
    const startDate = getIntervalFromValue(value, defaultEndDate)
    setStartDate(startDate)
    setEndDate(defaultEndDate)
    setApply(true)
    setTimeout(() => {
      setApply(false)
    }, 500)
  }

  useEffect(() => {
    if (value === 'Custom time interval') return
    if (!apply) return
    submit()
  }, [apply, value])

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <div>
      <div onClick={handleClick} className='dateFilter'>
        Date: {value}
      </div>
      <Menu
        id='time-filter-menu'
        anchorEl={anchorEl}
        sx={{ width: '350px' }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <FormControl component='fieldset'>
          <FormLabel component='legend' sx={{ marginLeft: '18px' }}>
            Date
          </FormLabel>
          <RadioGroup value={value} onChange={handleRadioChange}>
            <MenuItem>
              <FormControlLabel value='Last day' control={<Radio />} label='Last day' />
            </MenuItem>
            <MenuItem>
              <FormControlLabel value='Last 7 days' control={<Radio />} label='Last 7 days' />
            </MenuItem>
            <MenuItem>
              <FormControlLabel value='Last 30 days' control={<Radio />} label='Last 30 days' />
            </MenuItem>
            <MenuItem>
              <FormControlLabel value='Last 3 months' control={<Radio />} label='Last 3 months' />
            </MenuItem>
            <MenuItem>
              <FormControlLabel value='Last year' control={<Radio />} label='Last year' />
            </MenuItem>
            <MenuItem>
              <FormControlLabel
                value='Custom time interval'
                control={<Radio />}
                label='Custom time interval'
              />
            </MenuItem>
          </RadioGroup>
          <Button
            variant='contained'
            onClick={handleApply}
            className='buttonEnable'
            style={{ marginLeft: '18px', marginTop: '5px' }}
          >
            Apply
          </Button>
        </FormControl>
      </Menu>
    </div>
  )
}
