/* eslint-disable */
import { DataGrid } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import '../../globalStyle.scss'

export default function SearchedResultList({ columns, data }: any) {
 

  return (
    <div
      style={{
        marginTop: '10px',
        paddingRight: '10px',
        height: 'calc(100vh - 200px)',
        backgroundColor: '#FFFFFF',
        width: '98.5%',
        borderRadius: '5px',
      }}
    >
      <DataGrid
        hideFooter
        sx={{
          border: 0,
          '& .MuiDataGrid-row:hover': {
            backgroundColor: 'rgba(235, 235, 235, 0.7)',
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#FFFFFF',
            color: '#5B5B5B',
            fontSize: '15px',
            fontFamily: 'Segoe UI',
            borderBottom: '2px solid #BABABA',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: `1px solid #BABABA`,
            color: '#000000',
            fontSize: '12px',
            fontFamily: 'Segoe UI',
            cursor: 'pointer',
            fontWeight: '600',
          },
          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& ::-webkit-scrollbar': {
            width: '5px',
          },
          '& ::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1',
          },
          '& ::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '5px',
            '&:hover': {
              backgroundColor: '#555',
            },
          },
        }}
        columns={columns}
        rows={data || []}
      />
    </div>
  )
}
