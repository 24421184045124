/* eslint-disable */
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { LinearProgress, Paper } from '@mui/material'
import '../../globalStyle.scss'
import { useTranslation } from 'react-i18next'
import { displayTimeStr } from '../../utils/time'

function UploadLogList({ isLoading, data }: any) {
  const { t } = useTranslation()


  const displayList = data.map((item: any) => ({
    ...item,
    time: displayTimeStr(item.time),
  }))
  const columns = [
    {
      field: 'userEmail',
      headerName: 'User',
      flex: 1,
    },
    {
      field: 'filename',
      headerName: 'Filename',
      flex: 1,
    },
    {
      field: 'time',
      headerName: 'Time',
      flex: 1,
    },
  ]

  return (
    <DataGrid
      autoHeight={true}
      disableRowSelectionOnClick
      loading={isLoading}
      hideFooterSelectedRowCount
      slots={{
        loadingOverlay: LinearProgress,
        toolbar: GridToolbar,
      }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
        },
      }}
      columns={columns}
      rows={displayList}
      sx={{
        border: 0,
        '& .MuiDataGrid-row:hover': {
          backgroundColor: 'rgba(235, 235, 235, 0.7)',
        },
        '& .MuiDataGrid-columnHeaders': {
          backgroundColor: '#FFFFFF',
          color: '#5B5B5B',
          fontSize: '15px',
          fontFamily: 'Segoe UI',
          borderBottom: '2px solid #BABABA',
          // borderRadius: '15px',
        },
        '& .MuiDataGrid-cell': {
          borderBottom: `1px solid #BABABA`,
          color: '#000000',
          fontSize: '12px',
          fontFamily: 'Segoe UI',
          cursor: 'pointer',
          fontWeight: '600',
        },
        '& .MuiDataGrid-columnSeparator': {
          display: 'none',
        },
        '& ::-webkit-scrollbar': {
          width: '5px',
        },
        '& ::-webkit-scrollbar-track': {
          backgroundColor: '#f1f1f1',
        },
        '& ::-webkit-scrollbar-thumb': {
          backgroundColor: '#888',
          borderRadius: '5px',
          '&:hover': {
            backgroundColor: '#555',
          },
        },
        '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
          color: '#5B5B5B',
        },
        '& .MuiDataGrid-toolbarContainer .MuiSvgIcon-root': {
          fill: '#5B5B5B',
        },
      }}
    />
  )
}

export default UploadLogList
