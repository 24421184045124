/* eslint-disable */
import { useContext, useEffect, useState } from 'react'
import PlatformLayout from '../../components/PlatformLayout'
import '../../globalStyle.scss'
import { SearchParams } from '../../utils/interfaces'
import { fetchAlerts } from '../../apiCalls/alertsApi'
import AlertPageDataList from './AlertPageDataList'
import { useApiGet } from '../../apiCalls/useApi'
import DateFilter from '../InvestigationPage/DateFilter'
import { FormControl, InputLabel, MenuItem, Paper, Select } from '@mui/material'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { OrgContext } from '../../Org'
import DateRangePicker from '../../components/DateRangePicker'

export default function AlertPage({ pageName }: any) {
  const { org } = useContext(OrgContext)
  const appId = org.apps?.[0]?.id
  const appName = org.apps?.[0]?.name
  const defaultEndDate = dayjs().endOf('day').toDate()

  const { t } = useTranslation()
  const [values, setValues] = useState<SearchParams>({
    status: pageName === 'All Alerts' ? '' : pageName === 'Open Alerts' ? 'OPEN' : 'CLOSED',
    after: '',
  })

  const [statusFilter, setStatusFilter] = useState<string>('OPEN')
  const [riskFilter, setRiskFilter] = useState<string>('HIGH')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [data, setData] = useState({
    data: [],
    pageInfo: {},
  })

  const alertList = data.data
    ? {
        ...data,
        data: data.data
          .filter((item: any) => statusFilter === 'ALL' || item.status === statusFilter)
          .filter((item: any) => riskFilter === 'ALL' || item.riskLevel === riskFilter)
          .map((e: any) => ({
            ...e,
            riskLevel: t('riskLevels.' + e.riskLevel),
            statusText: t('alertStatusList.' + e.status),
            orgName: org.name,
            appName: appName,
            confirmationText:
              e.confirmation === 'WAITING' ? '' : t('alertConfirmationList.' + e.confirmation),
          })),
      }
    : []

  const [cursorHistory, setCursorHistory] = useState([''])
  const [needUpdate, setNeedUpdate] = useState(0)
  const fetchAlertsData = useApiGet(fetchAlerts)

  const [listFromDate, setListFromDate] = useState<Date | null>(
    dayjs().subtract(3, 'months').startOf('day').toDate()
  )
  const [listToDate, setListToDate] = useState<Date | null>(dayjs().endOf('day').toDate())
  const [isCustomDate, setIsCustomDate] = useState<boolean>(false)
  const reloadAlerts = () => {
    if (!appId) return
    fetchAlertsData({
      appId,
      startDate: dayjs(listFromDate).format('YYYY-MM-DD'),
      endDate: dayjs(listToDate).format('YYYY-MM-DD'),
    }).then((res: any) => {
      if (!res || !res.data || res.error) return
      setData({
        data: res?.data.map((e: any) => ({
          ...e,
        })),
        pageInfo: res?.pageInfo,
      })
      setIsLoading(false)
    })
  }

  function handleDateSubmitForList() {
    if (
      listFromDate &&
      dayjs(listFromDate).isValid() &&
      listToDate &&
      dayjs(listToDate).isValid()
    ) {
      const fromDateTimestamp = dayjs(listFromDate).valueOf()
      const toDateTimestamp = dayjs(listToDate).valueOf()
      if (fromDateTimestamp > toDateTimestamp) {
        return
      }
      setIsLoading(true)
      reloadAlerts()
    }
  }

  useEffect(() => {
    setIsLoading(true)
    if (!org.id) {
      return
    }
    reloadAlerts()
  }, [values, needUpdate, org])

  const handleStatusChange = (event: { target: { value: string } }) => {
    setStatusFilter(event.target.value)
  }

  const handleRiskChange = (event: { target: { value: string } }) => {
    setRiskFilter(event.target.value)
  }

  return (
    <PlatformLayout currentItem={pageName}>
      <Paper elevation={0} className='paperStyle'>
        <div style={{ height: 'calc(100% - 84px)' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <DateFilter
              setStartDate={setListFromDate}
              setEndDate={setListToDate}
              defaultEndDate={defaultEndDate}
              submit={reloadAlerts}
              setIsCustomDate={setIsCustomDate}
              setIsLoading={setIsLoading}
              isCustomDate={isCustomDate}
            />
            {isCustomDate && (
              <DateRangePicker
                fromDate={listFromDate}
                setFromDate={setListFromDate}
                toDate={listToDate}
                setToDate={setListToDate}
                submit={handleDateSubmitForList}
              />
            )}
          </div>
          <div style={{ marginTop: '8px' }}>
            <FormControl>
              <InputLabel>Risk</InputLabel>
              <Select
                classes={{ select: 'longSelect' }}
                value={riskFilter}
                label='Risk'
                onChange={handleRiskChange}
                sx={{
                  height: '36px',
                }}
              >
                <MenuItem value={'ALL'}>{t('ALL')}</MenuItem>
                <MenuItem value={'HIGH'}>{t('riskLevels.HIGH')}</MenuItem>
                <MenuItem value={'MEDIUM'}>{t('riskLevels.MEDIUM')}</MenuItem>
                <MenuItem value={'LOW'}>{t('riskLevels.LOW')}</MenuItem>
              </Select>
            </FormControl>
            <FormControl style={{ marginLeft: '6px' }}>
              <InputLabel>Status</InputLabel>
              <Select
                classes={{ select: 'longSelect' }}
                value={statusFilter}
                label='Status'
                onChange={handleStatusChange}
                sx={{
                  height: '36px',
                }}
              >
                <MenuItem value={'OPEN'}>Open</MenuItem>
                <MenuItem value={'CLOSED'}>Closed</MenuItem>
                <MenuItem value={'ALL'}>All</MenuItem>
              </Select>
            </FormControl>
          </div>

          <AlertPageDataList
            appId={appId}
            data={alertList}
            cursorHistory={cursorHistory}
            setCursorHistory={setCursorHistory}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            values={values}
            setValues={setValues}
            setNeedUpdate={setNeedUpdate}
            reload={reloadAlerts}
          />
        </div>
      </Paper>
    </PlatformLayout>
  )
}
