/* eslint-disable */
import React, { ChangeEvent, useEffect, useState } from 'react'
import PlatformLayout from '../../components/PlatformLayout'
import { Button, Paper, TextField } from '@mui/material'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import '../../globalStyle.scss'
import { getOrganizationInformation, setOrganizationInformation } from '../../apiCalls/settingApi'
import { OrganizationInformation } from '../../utils/interfaces'
import { useApiGet, useApiPost } from '../../apiCalls/useApi'

export default function OrganizationSetting() {
  const [information, setInformation] = useState<OrganizationInformation>({
    privacyOfficerName: '',
    privacyOfficerNumber: '',
    privacyOfficerEmail: '',
    alertNotificationEmail: '',
  })

  const fetchOrganizationInfo = useApiGet(getOrganizationInformation)
  const setOrganizationInfo = useApiPost(setOrganizationInformation)

  const [notEditable, setNotEditable] = useState(true)

  useEffect(() => {
    fetchOrganizationInfo().then((res) => {
      if (!res || !res.data || res.error) return
      const { data } = res
      setInformation({
        privacyOfficerName: data.privacyOfficerName,
        privacyOfficerNumber: data.privacyOfficerNumber,
        privacyOfficerEmail: data.privacyOfficerEmail,
        alertNotificationEmail: data.alertNotificationEmail,
      })
    })
  }, [notEditable])

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target
    setInformation({
      ...information,
      [name]: value,
    })
  }

  const handleSaveInformation = () => {
    setOrganizationInfo(information).then((_) => {
      setNotEditable(true)
    })
  }

  const inputStyles = {
    fontSize: 18,
    color: '#5B5B5B',
    fontWeight: '500',
  }

  const inputLabel = {
    fontSize: 20,
    background: '#fff',
    padding: '0 4px',
    color: '#9D9D9D',
    fontWeight: '600',
  }
  return (
    <PlatformLayout currentItem='Organization Setting'>
      <Paper
        square
        elevation={0}
        className='paperStyle'
        style={{
          width: '100%',
          borderRadius:'5px'
        }}
      >
        <div style={{ padding: '10px', position: 'relative' }}>
          <div className='organizationInfoSettingTitle'>Organization Information</div>
          <Button
            variant='contained'
            className='buttonEnable'
            style={{
              position: 'absolute',
              right: '50px',
              top: '30px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={() => {
              setNotEditable(false)
            }}
          >
            <EditOutlinedIcon sx={{ color: '#dedbdb' }} />
            Edit
          </Button>
          <div className='organizationInfoSettingTextInputContainer'>
            <TextField
              id='outlined-basic'
              name='privacyOfficerName'
              label='Privacy Officer Name'
              variant='outlined'
              value={information.privacyOfficerName}
              className='textInput'
              style={{ color: 'blue' }}
              disabled={notEditable}
              onChange={handleInputChange}
              InputProps={{ style: inputStyles }}
              InputLabelProps={{
                style: inputLabel,
              }}
            />

            <TextField
              id='outlined-basic'
              name='privacyOfficerNumber'
              label='Privacy Officer Telephone Number'
              variant='outlined'
              value={information.privacyOfficerNumber}
              className='textInput'
              disabled={notEditable}
              onChange={handleInputChange}
              InputProps={{ style: inputStyles }}
              InputLabelProps={{
                style: inputLabel,
              }}
            />

            <TextField
              id='outlined-basic'
              name='privacyOfficerEmail'
              label='Privacy Officer Email'
              variant='outlined'
              value={information.privacyOfficerEmail}
              className='textInput'
              disabled={notEditable}
              onChange={handleInputChange}
              InputProps={{ style: inputStyles }}
              InputLabelProps={{
                style: inputLabel,
              }}
            />

            <TextField
              id='outlined-basic'
              label='Alert Notification Email'
              name='alertNotificationEmail'
              variant='outlined'
              value={information.alertNotificationEmail}
              className='textInput'
              disabled={notEditable}
              onChange={handleInputChange}
              InputProps={{ style: inputStyles }}
              InputLabelProps={{
                style: inputLabel,
              }}
            />
          </div>
          {notEditable ? (
            ''
          ) : (
            <div className='organizationInfoSettingOperationContainer'>
              <Button
                variant='contained'
                className='buttonEnable'
                onClick={() => {
                  setNotEditable(true)
                }}
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                className='buttonEnable'
                onClick={handleSaveInformation}
                style={{ marginLeft: '12px' }}
              >
                Save
              </Button>
            </div>
          )}
        </div>
      </Paper>
    </PlatformLayout>
  )
}
