/* eslint-disable */
import { ResponsiveBar } from '@nivo/bar'
import GraphTitle from './GraphTitle'
import { dateToMonth } from './dateHelpler'
import {  legendColorStyle, legendItemStyle, legendStyle } from './commonStyle';
import {CircularProgress} from "@mui/material";

const AlertsBarChart = ({ isLoading, data, title, totalNumber, subNumbers }: any) => {
  const graphStyle = {
    height: '370px',
    backgroundColor: '#FFFFFF',
    marginTop: '2px',
  }

  const formatMonth = (dateString: string): string => {
    const [year, month] = dateString.split('-');
    const date = new Date(Number(year), Number(month) - 1);
    return dateToMonth(date)
  };

  return (
    <div>
      <GraphTitle title={title} totalNumber={totalNumber} subNumbers={subNumbers} />
      <div style={graphStyle}>
        { isLoading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <CircularProgress sx={{ color: '#A4A4A4' }} size='4rem' />
          </div>
        ) : (
          <ResponsiveBar
          data={data}
          keys={['high', 'medium', 'low']}
          indexBy='month'
          margin={{ top: 20, right: 35, bottom: 30, left: 40 }}
          padding={0.3}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          borderRadius={3}
          groupMode='grouped'
          colors={({ id }) => {
            if (id === 'high') return '#D06262'
            if (id === 'medium') return '#858dd6'
            if (id === 'low') return '#51BF91'
            return '#000000'
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendPosition: 'middle',
            legendOffset: 32,
            format: formatMonth
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendPosition: 'middle',
            legendOffset: -40,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          animate={true}
          motionConfig='gentle'
          theme={{
            axis: {
              ticks: {
                text: {
                  fontSize: 12,
                  fill: '#8B8080',
                  fontFamily: 'Segoe UI',
                },
              },
            },
            grid: {
              line: { stroke: '#ddd', strokeWidth: 1 },
            },
          }}
        />
          )}
      </div>
      <div style={legendStyle}>
          <div style={legendItemStyle}>
            <div style={legendColorStyle('#D06262')}></div>
            <div>High</div>
          </div>
          <div style={legendItemStyle}>
            <div style={legendColorStyle('#858dd6')}></div>
            <div>Medium</div>
          </div>
          <div style={legendItemStyle}>
            <div style={legendColorStyle('#51BF91')}></div>
            <div>Low</div>
          </div>
        </div>
    </div>
  )
}

export default AlertsBarChart
