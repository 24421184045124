/* eslint-disable */
import { ChangeEvent, useContext, useEffect, useState } from 'react'
import PlatformLayout from '../../components/PlatformLayout'
import SearchParameters from './SearchParameters'
import { Outlet, useNavigate } from 'react-router-dom'
import { OrgContext } from '../../Org'
import { searchProvider } from '../../apiCalls/searchApi'
import IsSearchingCircle from './IsSearchingCircleAndInfo'
import { useApiGet } from '../../apiCalls/useApi'

export default function Provider() {
  const { org } = useContext(OrgContext)
  const appId = org.apps?.[0]?.id
  const [searchID, setSearchID] = useState<string>('')
  const [firstname, setFirstname] = useState<string>('')
  const [lastname, setLastname] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const fetchProvider = useApiGet(searchProvider)
  const navigate = useNavigate()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('searchID');
    setSearchID(id as string || '');
  }, [location.search]);

  const handleSearchId = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchID(event.target.value)
  }
  const handleFirstname = (event: ChangeEvent<HTMLInputElement>) => {
    setFirstname(event.target.value)
  }

  const handleLastname = (event: ChangeEvent<HTMLInputElement>) => {
    setLastname(event.target.value)
  }

  const onSearch = async () => {
    if (searchID !== '' || firstname !== '' || lastname !== '') {
        setIsLoading(true)

      const providerResult = await fetchProvider({
        appId,
        id: searchID,
        firstName: firstname,
        lastName: lastname,
      })
      
      if (providerResult && !providerResult.error && providerResult.data) {
        setIsLoading(false)
        navigate('providersResult', { state: { providerResult:providerResult.data } });
      }
    }
  }
  
  return (
    <PlatformLayout currentItem='Provider'>
      <div
        style={{
          width: '100%',
          marginLeft: '15px',
          height: 'calc(100vh - 116px)',
        }}
      >
        <SearchParameters
          title='Provider Search Parameters'
          searchID={searchID}
          firstname={firstname}
          lastname={lastname}
          handleSearchId={handleSearchId}
          handleFirstname={handleFirstname}
          handleLastname={handleLastname}
          onSearch={onSearch}
        />
        {isLoading ? <IsSearchingCircle/> : <Outlet/>}
      </div>
    </PlatformLayout>
  )
}
