/* eslint-disable */
import '../../globalStyle.scss'
import { useTranslation } from 'react-i18next'
import DashboardDataList from './DashboardDataList'
import { dateToLocal } from './dateHelpler'

function LatestAlertDataList({ isLoading, data }: any) {
  const { t } = useTranslation()

  const columns = [
    {
      field: 'date',
      headerName: 'Date',
      flex: 1,
      valueFormatter: (params: any) => {
        const date = new Date(params.value)
        return dateToLocal(date)
      },
    },
    { field: 'providerId', headerName: 'Provider Id', flex: 1 },
    { field: 'providerName', headerName: 'Provider Name', flex: 2 },
    {
      field: 'riskLevel',
      headerName: 'Risk',
      flex: 1,
    },
  ]

  const titleStyle = {
    height: '45px',
    color: '#A3A3A3',
    fontWeight: '600',
    paddingLeft: '20px',
    paddingTop: '5px',
    backgroundColor: '#FFFFFF',
  }

  return (
    <div>
      <div style={titleStyle}>Latest 20 alerts</div>
      <DashboardDataList isLoading={isLoading} data={data} columns={columns} />
    </div>
  )
}

export default LatestAlertDataList
