/* eslint-disable */
import { useContext, useEffect, useState } from 'react'
import PlatformLayout from '../../components/PlatformLayout'
import { Button, Paper, Typography } from '@mui/material'
import '../../globalStyle.scss'
import { getEubaRules, updateRules } from '../../apiCalls/rulesApi'
import { DataGrid } from '@mui/x-data-grid'
import Switch from '@mui/material/Switch'
import { useNavigate } from 'react-router-dom'
import { OrgContext } from '../../Org'
import { useApiGet, useApiPost } from '../../apiCalls/useApi'
import { ConfirmContext } from '../../Confirm'

type Rule = {
  alertType: string
  appId: string
  appName: string
  id: string
  status: string
  type: string
}

const rulesSort = [
  'Access Outside Working Days',
  'Access Outside Working Hours',
  'Access After Long Period',
  'Provider First Access',
  'Inactive Provider Access',
  'Access After Discharged',
  'Excessive Access',
]

export const rulesMapping: any = {
  AccessOutsideWorkingHours: 'Access Outside Working Hours',
  AccessOutsideWorkingDays: `Access Outside Working Days`,
  AccessAfterLongPeriod: `Access After Long Period`,
  ProviderFirstAccess: `Provider First Access`,
  AccessAfterDischarged: `Access After Discharged`,
  InactiveProviderAccess: `Inactive Provider Access`,
  ExcessiveAccess: `Excessive Access`,
}

export default function EubaRules() {
  const { org } = useContext(OrgContext)
  const appId = org.apps?.[0]?.id

  const { openConfirm, closeConfirm, setConfirmSettings } = useContext(ConfirmContext)

  const [rules, setRules] = useState([])
  const [isRulesLoading, setIsRulesLoading] = useState<boolean>(false)
  const [chosenRule, setChosenRule] = useState<Rule | null>(null)
  const [chosenType, setChosenType] = useState<string>('')
  const navigate = useNavigate()

  const fetchRules = useApiGet(getEubaRules)
  const updateRuleSetting = useApiPost(updateRules)

  useEffect(() => {
    if (!appId) return
    setIsRulesLoading(true)
    fetchRules({ appId }).then((res: any) => {
      if (!res || !res.data || res.error) return
      const {data} = res
      data.sort((a: any, b: any) => {
        return rulesSort.indexOf(a.type) - rulesSort.indexOf(b.type)
      })
      setRules(res.data)
      setIsRulesLoading(false)
    })
  }, [appId])

  const handleSwitch = async () => {
    if (!chosenRule) return
    if (chosenType === 'status') {
      if (chosenRule[chosenType] === 'ON') {
        chosenRule[chosenType] = 'OFF'
      } else {
        chosenRule[chosenType] = 'ON'
      }
    } else {
      return
    }

    const err = await updateRuleSetting({
      appId,
      id: chosenRule.id,
      alertType: 'ALERT',
      status: chosenRule.status,
    })
    if (err) return

    const changedRules: any = rules.map((element: any) => {
      if (element.type === chosenRule.type) return { ...chosenRule }
      return { ...element }
    })
    setRules(changedRules)
    handleClose()
  }

  useEffect(() => {
    const te = chosenRule ? `Switch ${rulesMapping[chosenRule.type]} 's ${chosenType}?` : ''

    setConfirmSettings({
      text: te,
      confirmCallback: async () => {
        handleSwitch()
      },
    })
  }, [chosenRule])

  const handleChange = (data: any, type: string) => {
    openConfirm()
    setChosenRule(data)
    setChosenType(type)
  }

  const handleClose = () => {
    setChosenRule(null)
    setChosenType('')
    closeConfirm()
  }

  const columns = [
    {
      field: 'type',
      headerName: 'Rules',
      flex: 1,
      renderCell: (params: any) => <div>{rulesMapping[params.value]}</div>,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params: any) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography variant='body2' color='textSecondary' style={{ marginRight: 8 }}>
            Off
          </Typography>
          <Switch
            checked={params.value === 'ON'}
            onChange={() => handleChange(params.row, 'status')}
          />
          <Typography variant='body2' color='textSecondary' style={{ marginLeft: 8 }}>
            On
          </Typography>
        </div>
      ),
    },
    {
      field: 'setting',
      headerName: 'Setting',
      flex: 0.6,
      renderCell: (params: any) => {
        return (
          <Button
            variant='contained'
            className='ruleSettingDetails'
            onClick={() => {
              navigate(`/eubaRules/${params.row.id}/edit?type=${params.row.type}`)
            }}
          >
            Edit
          </Button>
        )
      },
    },
  ]

  return (
    <PlatformLayout currentItem='EUBA Rules'>
      <Paper
        square
        elevation={0}
        className='paperStyle'
      >
        <div className='ruleSettingTitle'>
          <div className='ruleSettingTitle'>EUBA Rules</div>
          <div className='ruleCount'>{rules.length}</div>
          <div className='ruleTotal'>total</div>
        </div>

        <div className='ruleList'>
          <DataGrid
            loading={isRulesLoading}
            rows={rules}
            hideFooter
            columns={columns}
            sx={{
              border: 0,
              '& .MuiDataGrid-row:hover': {
                backgroundColor: 'rgba(235, 235, 235, 0.7)',
              },
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: '#FFFFFF',
                color: '#5B5B5B',
                fontSize: '15px',
                fontFamily: 'Segoe UI',
                borderBottom: '2px solid #BABABA',
                // borderRadius: '15px',
              },
              '& .MuiDataGrid-cell': {
                borderBottom: `1px solid #BABABA`,
                color: '#000000',
                fontSize: '12px',
                fontFamily: 'Segoe UI',
                cursor: 'pointer',
                fontWeight: '600',
              },
              '& .MuiDataGrid-columnSeparator': {
                display: 'none',
              },
              '& ::-webkit-scrollbar': {
                width: '5px',
              },
              '& ::-webkit-scrollbar-track': {
                backgroundColor: '#f1f1f1',
              },
              '& ::-webkit-scrollbar-thumb': {
                backgroundColor: '#888',
                borderRadius: '5px',
                '&:hover': {
                  backgroundColor: '#555',
                },
              },
              '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
                color: '#5B5B5B',
              },
              '& .MuiDataGrid-toolbarContainer .MuiSvgIcon-root': {
                fill: '#5B5B5B',
              },
            }}
          />
        </div>
      </Paper>
    </PlatformLayout>
  )
}
