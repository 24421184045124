import { createBrowserRouter } from 'react-router-dom'
import Dashboard from './pages/Dashboard'
import Auth from './pages/Auth'
import PrivateRoute from './components/PrivateRoute'
import AlertPage from './pages/AlertPage'
import OrganizationSetting from './pages/OrganizationSetting'
import AlertIndicator from './pages/AlertIndicators'
import RuleSetting from './pages/RulesSetting'
import SettingDetails from './pages/RulesSetting/SettingDetails'
import AlertDashboard from './pages/AlertPage/AlertDashboard'
import InvestigationDashboard from './pages/InvestigationPage/InvestigationDashboard'
import HelpPage from './pages/HelpPage'
import Provider from './pages/InvestigationPage/Provider'
import AllProviders from './pages/InvestigationPage/AllProviders'
import ProvidersResult from './pages/InvestigationPage/ProvidersResult'
import ProviderInvestigation from './pages/InvestigationPage/ProviderInvestigation'
import ClientInvestigation from './pages/InvestigationPage/ClientInvestigation'
import Client from './pages/InvestigationPage/Client'
import AllClients from './pages/InvestigationPage/AllClients'
import ClientsResult from './pages/InvestigationPage/ClientsResult'
import HelpContent from './pages/HelpPage/HelpContent'
import Upload from './pages/Upload'
import EubaRules from './pages/EubaRules'
import EubaExceptions from './pages/EubaRules/EubaExceptions'

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <PrivateRoute>
        <Dashboard />
      </PrivateRoute>
    ),
  },
  {
    path: '/auth',
    element: <Auth />,
  },
  {
    path: '/alerts',
    element: (
      <PrivateRoute>
        <AlertPage pageName='Alerts' />
      </PrivateRoute>
    ),
  },
  {
    path: '/indicators',
    element: (
      <PrivateRoute>
        <AlertIndicator pageName='Indicators' />
      </PrivateRoute>
    ),
  },
  {
    path: '/provider',
    element: (
      <PrivateRoute>
        <Provider />
      </PrivateRoute>
    ),
    children: [
      {
        index: true,
        element: (
          <PrivateRoute>
            <AllProviders />
          </PrivateRoute>
        ),
      },
      {
        path: 'providersResult',
        element: (
          <PrivateRoute>
            <ProvidersResult />
          </PrivateRoute>
        ),
      },
      {
        path: 'providerInvestigation',
        element: (
          <PrivateRoute>
            <ProviderInvestigation />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    path: '/client',
    element: (
      <PrivateRoute>
        <Client />
      </PrivateRoute>
    ),
    children: [
      {
        index: true,
        element: (
          <PrivateRoute>
            <AllClients />
          </PrivateRoute>
        ),
      },
      {
        path: 'clientsResult',
        element: (
          <PrivateRoute>
            <ClientsResult />
          </PrivateRoute>
        ),
      },
      {
        path: 'clientInvestigation',
        element: (
          <PrivateRoute>
            <ClientInvestigation />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    path: 'organizationSetting',
    element: (
      <PrivateRoute>
        <OrganizationSetting />
      </PrivateRoute>
    ),
  },
  {
    path: '/detectionRules',
    element: (
      <PrivateRoute>
        <RuleSetting />
      </PrivateRoute>
    ),
  },
  {
    path: '/eubaRules',
    element: (
      <PrivateRoute>
        <EubaRules/>
      </PrivateRoute>
    ),
  },
  
  {
    path: '/detectionRules/:id/edit',
    element: (
      <PrivateRoute>
        <SettingDetails />
      </PrivateRoute>
    ),
  },

  {
    path: '/eubaRules/:id/edit',
    element: (
      <PrivateRoute>
        <EubaExceptions />
      </PrivateRoute>
    ),
  },
  {
    path: '/alertDashboard',
    element: (
      <PrivateRoute>
        <AlertDashboard />
      </PrivateRoute>
    ),
  },
  {
    path: '/investigationDashboard',
    element: (
      <PrivateRoute>
        <InvestigationDashboard />
      </PrivateRoute>
    ),
  },
  {
    path: '/help',
    element: (
      <PrivateRoute>
        <HelpPage />
      </PrivateRoute>
    ),
    children: [
      {
        index: true,
        element: (
          <PrivateRoute>
            <HelpContent helpID="dashboard"/>
          </PrivateRoute>
        ),
      },
      {
        path: 'dashboardHelp',
        element: (
          <PrivateRoute>
            <HelpContent helpID="dashboard"/>
          </PrivateRoute>
        ),
      },
      {
        path: 'alertsHelp',
        element: (
          <PrivateRoute>
            <HelpContent helpID="alert"/>
          </PrivateRoute>
        ),
      },
      {
        path: 'indicatorsHelp',
        element: (
          <PrivateRoute>
            <HelpContent helpID="indicator"/>
          </PrivateRoute>
        ),
      },
      {
        path: 'providerSearchHelp',
        element: (
          <PrivateRoute>
            <HelpContent helpID="providerSearch"/>
          </PrivateRoute>
        ),
      },
      {
        path: 'providerInvestigationHelp',
        element: (
          <PrivateRoute>
            <HelpContent helpID="providerInvestigation"/>
          </PrivateRoute>
        ),
      },
      {
        path: 'clientSearchHelp',
        element: (
          <PrivateRoute>
           <HelpContent helpID="clientSearch"/>
          </PrivateRoute>
        ),
      },
      {
        path: 'clientInvestigationHelp',
        element: (
          <PrivateRoute>
            <HelpContent helpID="clientInvestigation"/>
          </PrivateRoute>
        ),
      },
      {
        path: 'orgSettingHelp',
        element: (
          <PrivateRoute>
            <HelpContent helpID="orgSetting"/>
          </PrivateRoute>
        ),
      },
      {
        path: 'ruleSettingHelp',
        element: (
          <PrivateRoute>
            <HelpContent helpID="ruleSetting"/>
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    path:  '/upload',
    element: (
      <PrivateRoute>
        <Upload />
      </PrivateRoute>
    ),
  },
])

export default router
