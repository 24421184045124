/* eslint-disable */
import {
  Box,
  Collapse,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import PrivacyTipOutlinedIcon from '@mui/icons-material/PrivacyTipOutlined'
import CloudUploadIcon from '@mui/icons-material/CloudUploadOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop'
import logoutApi from '../../apiCalls/authApi'
import { redirectToLogout } from '../../Auth'
import RIAppBar from '../AppBar'
import RIBreadcrumbsNav from '../RIBreadCrumbNav'
import '../../globalStyle.scss'
import ErrorContext, { SuccessContext } from '../../ErrorContext'
import Confirm from '../Confirm'
import { ConfirmContext } from '../../Confirm'
import ErrorSnackAlert from './ErrorSnackAlert'
import SuccessSnackAlert from './SuccessSnackAlert'

type SideNavBarProps = {
  currentItem: string
  children: React.ReactNode
}

export default function PlatformLayout({ currentItem, children }: SideNavBarProps) {
  const navigate = useNavigate()
  const getSideBarOpenStatus = sessionStorage.getItem('sideBarOpenStatus')
  const parse = getSideBarOpenStatus === null ? {} : JSON.parse(getSideBarOpenStatus)
  const [open, setOpen] = useState<any>(parse)
  const { isError, setIsError } = useContext(ErrorContext)
  const { isSuccess, setIsSuccess } = useContext(SuccessContext)

  const { isConfirmOpen, openConfirm, closeConfirm, confirmSettings } = useContext(ConfirmContext)

  const handleToggleSubmenu = (text: any) => {
    const newStatus = JSON.stringify({
      ...open,
      [text]: !open[text],
    })
    sessionStorage.setItem('sideBarOpenStatus', newStatus)
    setOpen({
      ...open,
      [text]: !open[text],
    })
  }

  const navItems = [
    {
      text: 'Dashboard',
      icon: <SpaceDashboardOutlinedIcon />,
      path: '/',
    },
    {
      text: 'Alerts',
      icon: <PrivacyTipOutlinedIcon />,
      children: [
        { text: 'Alert Dashboard', path: '/alertDashboard' },
        {
          text: 'Alerts',
          path: '/alerts',
        },
        {
          text: 'Indicators',
          path: '/indicators',
        },
      ],
    },
    {
      text: 'Investigation',
      icon: <ScreenSearchDesktopIcon />,
      children: [
        { text: 'Investigation Dashboard', path: '/investigationDashboard' },
        {
          text: 'Provider',
          path: '/provider',
        },
        {
          text: 'Client',
          path: '/client',
        },
      ],
    },
    {
      text: 'Upload',
      icon: <CloudUploadIcon />,
      path: '/upload',
    },
    {
      text: 'Setting',
      icon: <SettingsOutlinedIcon />,
      children: [
        {
          text: 'Organization Setting',
          path: '/organizationSetting',
        },
        {
          text: 'Detection Rules',
          path: '/detectionRules',
        },
        {
          text: 'EUBA Rules',
          path: '/eubaRules',
        },
      ],
    },
    {
      text: 'Logout',
      icon: <ExitToAppIcon />,
      path: '/logout',
    },
  ]

  const handleNavigation = (path: string) => {
    if (path === '/logout') {
      Cookies.remove('auth_token')
      logoutApi()
        .catch()
        .finally(() => {
          redirectToLogout()
        })
      return
    }

    navigate(path)
  }

  const handleCloseError = () => setIsError(false)
  const handleCloseSuccess = () => setIsSuccess(false)

  async function handleConfirm() {
    await confirmSettings.confirmCallback()
    closeConfirm()
  }

  return (
    <div
      style={{
        boxSizing: 'border-box',
        position: 'relative',
        overflowX: 'hidden',
        width: '100%',
      }}
    >
      <div
        style={{
          position: 'fixed',
          width: '100%',
          top: '0',
          zIndex: 9999,
        }}
      >
        <RIAppBar />
      </div>

      <div className='breadCrumb'>
        <RIBreadcrumbsNav />
      </div>

      <Grid container>
        <Grid
          item
          xs={0}
          md={2}
          sx={{
            display: {
              xs: 'none',
              md: 'block',
            },
          }}
        >
          {/* sidebar */}
          <Box
            sx={{
              height: '100vh',
              width: {
                xs: 0,
                md: '210px',
              },
              backgroundColor: '#FFFFFF',
              // paddingTop: '17px',
              position: 'fixed',
              top: '53px',
            }}
          >
            <div>
              <div>
                <List>
                  {navItems.map((item, index) => (
                    <React.Fragment key={index}>
                      <ListItem
                        button
                        onClick={() => {
                          item.children
                            ? handleToggleSubmenu(item.text)
                            : handleNavigation(item.path)
                        }}
                      >
                        <ListItemIcon
                          sx={
                            item.text === currentItem ? { color: '#000000' } : { color: '#5B5B5B' }
                          }
                        >
                          {item.icon}
                        </ListItemIcon>

                        <ListItemText
                          primary={item.text}
                          sx={{
                            ...(item.text === currentItem
                              ? { color: '#000000' }
                              : { color: '#5B5B5B' }),
                            '& .MuiTypography-root': {
                              fontSize: '15px',
                              fontWeight: '600',
                            },
                          }}
                        />
                        {item.children && (
                          <IconButton>
                            {open[item.text] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                          </IconButton>
                        )}
                      </ListItem>
                      {item.children && (
                        <Collapse in={open[item.text]} timeout='auto' unmountOnExit>
                          <List component='div' disablePadding>
                            {item.children.map((subItem, subIndex) => (
                              <ListItem
                                button
                                key={subIndex}
                                onClick={() => handleNavigation(subItem.path)}
                              >
                                <ListItemText
                                  inset
                                  primary={subItem.text}
                                  sx={{
                                    ...(subItem.text === currentItem
                                      ? { color: '#000000' }
                                      : { color: '#5B5B5B' }),
                                    '& .MuiTypography-root': {
                                      fontSize: '14px',
                                      fontWeight: '600',
                                    },
                                  }}
                                />
                              </ListItem>
                            ))}
                          </List>
                        </Collapse>
                      )}
                    </React.Fragment>
                  ))}
                </List>
              </div>
            </div>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={11}
          sx={{
            marginTop: '114px',
            marginLeft: {
              md: '210px',
              xs: 0,
            },
            display: 'flex',
            justifyContent: 'center',
            minHeight: '80vh',
          }}
        >
          {children}
          <ErrorSnackAlert open={isError} handleCloseError={handleCloseError} />
          <SuccessSnackAlert open={isSuccess} handleCloseError={handleCloseSuccess} />
          <Confirm
            isOpen={isConfirmOpen}
            text={confirmSettings.text}
            handleConfirm={handleConfirm}
            handleClose={closeConfirm}
          />
        </Grid>
      </Grid>
    </div>
  )
}
