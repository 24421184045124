/* eslint-disable */
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import '../../globalStyle.scss'
import AllPersonDataList from './AllPersonDataList'
import { useApiGet } from '../../apiCalls/useApi'
import { getAllClientsByPagination } from '../../apiCalls/alertsApi'
import { useContext, useEffect, useState } from 'react'
import { OrgContext } from '../../Org'

export default function AllClients() {
  const { org } = useContext(OrgContext)
  const appId = org.apps?.[0]?.id
  const callGetAllClientsByPaginationApi = useApiGet(getAllClientsByPagination)
  const [data, setData] = useState({ data: [], total: 0 })
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  })
  const navigate = useNavigate()

  useEffect(() => {
    if (appId) {
      setIsLoading(true)
      callGetAllClientsByPaginationApi({
        appId,
        limit: paginationModel.pageSize,
        offset: paginationModel.page * paginationModel.pageSize,
      }).then((res: any) => {
        if (res?.data) {
          setData({ data: res.data, total: res.total })
          setIsLoading(false)
        }
      })
    }
  }, [appId, paginationModel])

  const columns = [
    {
      field: 'id',
      headerName: 'Client Id',
      flex: 1,
      renderCell: (params: any) => (
        <div
          className='linkStyle'
          onClick={() => {
            navigate(`/client/clientInvestigation`, { state: { searchID: params.value } })
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'firstAccessTime',
      headerName: 'First Access Time',
      flex: 1,
      renderCell: (params: any) => {
        return dayjs(params.value).format('YYYY-MM-DD HH:mm:ss')
      },
    },
    {
      field: 'lastAccessTime',
      headerName: 'Last Access Time',
      flex: 1,
      renderCell: (params: any) => {
        return dayjs(params.value).format('YYYY-MM-DD HH:mm:ss')
      },
    },
  ]

  return (
    <AllPersonDataList
      data={data.data}
      columns={columns}
      paginationModel={paginationModel}
      setPaginationModel={setPaginationModel}
      total={data.total}
      isLoading={isLoading}
    />
  )
}
