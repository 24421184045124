/* eslint-disable */

import { useEffect, useRef, useState } from 'react'

import cytoscape from 'cytoscape'
import { Button } from '@mui/material'

import IsSearchingCircle from './IsSearchingCircleAndInfo'
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined'
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined'
import DetailsSideBar from './DetailsSideBar'

export default function AccessChart({
  type,
  nodes,
  edges,
  sidebarVisible,
  setSidebarVisible,
  isLoading,
  info,
  graphType,
}: any) {
  const cyContainer = useRef<any>(null)
  const [hoverInfo, setHoverInfo] = useState({
    centerNode: '',
    hoveredNode: '',
    accessCount: 0,
  })

  useEffect(() => {
    if (cyContainer.current) {
      const cy = cytoscape({
        container: cyContainer.current,
        elements: nodes.concat(edges),
        style: [
          {
            selector: 'node',
            style: {
              label: 'data(label)',
              'text-valign': 'center',
              'text-halign': 'center',
              'background-color': '#858585',
              color: '#ffffff',
              shape: 'round-rectangle',
              'font-size': '12px',
              width: 'label',
              height: 30,
              'padding-bottom': '3px',
              'padding-left': '3px',
              'padding-right': '3px',
              'padding-top': '3px',
            },
          },
          {
            selector: 'node.mainNode',
            style: {
              'background-color': '#2D2F32',
            },
          },
          {
            selector: 'edge',
            style: {
              width: 3,
              'line-color': '#D4D4D4',
              'curve-style': 'bezier',
              label: 'data(label)',
              'text-background-color': '#fff',
              'text-background-opacity': 1,
              'text-background-padding': '5px',
              'text-background-shape': 'roundrectangle',
              'font-size': '13px',
              color: '#000000',
              'text-rotation': 'autorotate',
            },
          },
        ],
        layout: {
          name: graphType as any,
          fit: true,
          minNodeSpacing: 20,
          spacingFactor: 0.9,
          avoidOverlap: true,
        },
      })

      let isAnimating = false
      const nodeCount = cy.nodes().length
      const scaleFactor = nodeCount > 40 ? 2.5 : nodeCount > 20 ? 1.5 : 1.1

      cy.on('mouseover', 'node', (event) => {
        const node = event.target
        if (node.hasClass('mainNode')) {
          return
        }
        const centerNode = cy.$('.mainNode')

        const connectedEdge = node
          .connectedEdges()
          .filter(
            (edge: any) =>
              edge.source().id() === centerNode.id() || edge.target().id() === centerNode.id()
          )

        const accessCount = connectedEdge.data('label')

        const info = {
          centerNode: centerNode.data('label'),
          hoveredNode: node.data('label'),
          accessCount: accessCount,
        }

        setHoverInfo(info)

        if (isAnimating) return

        isAnimating = true

        node.animate({
          style: {
            width: `${150 * scaleFactor}px`,
            height: `${80 * scaleFactor}px`,
            'font-size': `${18 * scaleFactor}px`,
          },
          duration: 100,
          easing: 'ease-out',
          complete: () => {
            isAnimating = false
          },
        })
        node.connectedEdges().forEach((edge: any) => {
          const sourceId = edge.source().id()
          const targetId = edge.target().id()

          if (
            cy.$(`#${sourceId}`).hasClass('mainNode') ||
            cy.$(`#${targetId}`).hasClass('mainNode')
          ) {
            edge.animate({
              style: {
                'font-size': `${20 * scaleFactor}px`,
              },
              duration: 100,
              easing: 'ease-out',
            })
          }
        })

        cy.nodes().forEach((n) => {
          const distance = node.neighborhood().intersection(n).length
          if (distance > 0 && distance === 1) {
            n.animate({
              style: {
                width: `${150 * scaleFactor}px`,
                height: `${80 * scaleFactor}px`,
                'font-size': `${18 * scaleFactor}px`,
              },
              duration: 100,
              easing: 'ease-out',
            })
          }
        })
      })

      cy.on('mouseout', 'node', (event) => {
        setHoverInfo({
          centerNode: '',
          hoveredNode: '',
          accessCount: 0,
        })
        cy.nodes().forEach((n) => {
          n.animate({
            style: {
              width: 'label',
              height: 30,
              'font-size': '12px',
            },
            duration: 50,
            easing: 'ease-out',
          })
        })
        cy.edges().forEach((edge) => {
          edge.animate({
            style: {
              'font-size': '13px',
            },
            duration: 50,
            easing: 'ease-out',
          })
        })
      })
    }
  }, [nodes, edges, graphType])

  return (
    <div style={{ height: 'calc(100% - 42px)' }}>
      <div style={{ height: '100%' }}>
        <div className='graphBodyContainer'>
          <div className='graphBody'>
            {isLoading || info.length > 0 ? (
              <IsSearchingCircle info={info} />
            ) : (
              <div ref={cyContainer} style={{ width: '100%', height: '100%' }}></div>
            )}
          </div>
        </div>

        <DetailsSideBar sidebarVisible={sidebarVisible} hoverInfo={hoverInfo} type={type} />

        <Button
          onClick={() => setSidebarVisible(!sidebarVisible)}
          sx={{
            width: '20px',
            height: '80px',
            minWidth: '20px',
            minHeight: '80px',
            backgroundColor: '#8B8B8B',
            padding: 0,
            '&:hover': {
              backgroundColor: '#6A6A6A',
            },
          }}
          style={{
            position: 'fixed',
            right: sidebarVisible ? '300px' : '9px',
            top: '600px',
          }}
        >
          {sidebarVisible ? (
            <ArrowForwardIosOutlinedIcon sx={{ color: '#FFFFFF' }} />
          ) : (
            <ArrowBackIosOutlinedIcon sx={{ color: '#FFFFFF' }} />
          )}
        </Button>
      </div>
    </div>
  )
}
