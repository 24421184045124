import { QueryClient, QueryClientProvider } from 'react-query'
import { RouterProvider } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { AuthContext } from './Auth'
import router from './router'
import ErrorContext, { SuccessContext } from './ErrorContext'
import { OrgContext } from './Org'
import { ConfirmContext, ConfirmSettingsType } from './Confirm'
import { getOrganizationInformation } from './apiCalls/settingApi'

const queryClient = new QueryClient()

const theme = createTheme({
  typography: {
    fontFamily: ['Segoe UI'].join(','),
  },
})

function App() {
  const [auth, setAuth] = useState(null)
  const [isError, setIsError] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [org, setOrg] = useState({
    id: '',
    name: '',
    apps: [],
  })
  const [confirmSettings, setConfirmSettings] = useState({
    text: 'Are you sure?',
    confirmCallback: () => Promise.resolve(),
  })
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)

  const memoAuth = useMemo(
    () => ({
      auth,
      setAuth,
    }),
    [auth, setAuth]
  )

  const memoOrg = useMemo(
    () => ({
      org,
      setOrg,
    }),
    [org, setOrg]
  )

  const memoError = useMemo(
    () => ({
      isError,
      setIsError,
    }),
    [isError, setIsError]
  )

  const memoSuccess = useMemo(
    () => ({
      isSuccess,
      setIsSuccess,
    }),
    [isSuccess, setIsSuccess]
  )

  const memoConfirm = useMemo(
    () => ({
      confirmSettings,
      setConfirmSettings: ({ text, confirmCallback }: ConfirmSettingsType) => {
        setConfirmSettings({
          text,
          confirmCallback,
        })
      },
      isConfirmOpen,
      openConfirm: () => setIsConfirmOpen(true),
      closeConfirm: () => setIsConfirmOpen(false),
    }),
    [confirmSettings, isConfirmOpen, setConfirmSettings, setIsConfirmOpen]
  )

  useEffect(() => {
    if (!auth) {
      return
    }
    getOrganizationInformation().then(({ data, error }) => {
      if (error) {
        return
      }
      const d = data.data
      setOrg({
        id: d.id,
        name: d.name,
        apps: d.apps,
      })
    })
  }, [auth])

  // TODO i18n, layout
  return (
    <div className='App'>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <ErrorContext.Provider value={memoError}>
            <SuccessContext.Provider value={memoSuccess}>
              <AuthContext.Provider value={memoAuth}>
                <OrgContext.Provider value={memoOrg}>
                  <ConfirmContext.Provider value={memoConfirm}>
                    <RouterProvider router={router} />
                  </ConfirmContext.Provider>
                </OrgContext.Provider>
              </AuthContext.Provider>
            </SuccessContext.Provider>
          </ErrorContext.Provider>
        </QueryClientProvider>
      </ThemeProvider>
    </div>
  )
}

export default App
