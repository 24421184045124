import SnackAlert from './SnackAlert'

export type TypeSnackAlertParams = {
  open: boolean
  handleCloseError: () => void
  text?: string
}

export default function ErrorSnackAlert({ open, handleCloseError, text }: TypeSnackAlertParams) {
  const displayText = text ?? 'Something went wrong! Please try again later.'
  return (
    <SnackAlert
      open={open}
      handleCloseError={handleCloseError}
      text={displayText}
      severity='error'
      autoHideDuration={6000}
    />
  )
}
