import { Button, CircularProgress, Modal } from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { styled } from '@mui/material/styles'
import { useContext, useState } from 'react'
import { useApiGet } from '../../apiCalls/useApi'
import { getUploadUrl } from '../../apiCalls/uploadApi'
import { apiUpload } from '../../apiCalls/apiPost'
import ErrorSnackAlert from '../../components/PlatformLayout/ErrorSnackAlert'
import { SuccessContext } from '../../ErrorContext'
import Confirm from '../../components/Confirm'

const VisuallyHiddenInput = styled('input')({
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

export default function UploadLog({ reload }: { reload: () => void }) {
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string|undefined>(undefined)
  const [isConfirming, setIsConfirming] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [file, setFile] = useState<File | undefined>(undefined)

  const { setIsSuccess } = useContext(SuccessContext)

  const fetchUploadUrl = useApiGet(getUploadUrl)

  const handleCloseError = () => setIsError(false)

  const handleFileChange = async ({ target }: { target: HTMLInputElement }) => {
    if (!target.files || target.files.length <= 0) {
      return
    }
    const targetFile = target.files[0];
    if (targetFile.size > 320 * 1024 * 1024) {
      setErrorMessage('File size exceeds 320MB. Please upload a smaller file.')
      setIsError(true)
      return
    }
    setFile(targetFile)
    setIsConfirming(true)
  }

  async function handleConfirm() {
    if (!file) {
      return
    }
    setIsUploading(true)
    try {
      const { data } = await fetchUploadUrl({ filename: file.name })
      await apiUpload(data, file)
      setIsSuccess(true)
      reload()
    } catch (e) {
      console.error(e)
      setErrorMessage(undefined)
      setIsError(true)
    }
    setIsUploading(false)
    setIsConfirming(false)
  }

  const aaa = `Are you sure you want to upload ${file?.name}?`
  const bbb = 'Please upload in chronological order. File maxsize is 320MB.'

  const handleClose = () => {
    setIsConfirming(false)
    setFile(undefined)
  }

  const logType = '.xml,text/xml';

  return (
    <>
      <Button
        component='label'
        role={undefined}
        variant='contained'
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}
      >
        Upload Log
        <VisuallyHiddenInput onChange={handleFileChange} type='file' accept={logType}/>
      </Button>
      <ErrorSnackAlert open={isError} handleCloseError={handleCloseError} text={errorMessage} />
      <Confirm
        isOpen={isConfirming}
        text={aaa}
        warning={bbb}
        handleConfirm={handleConfirm}
        handleClose={handleClose}
      />
      <Modal open={isUploading}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <CircularProgress sx={{ color: '#A4A4A4' }} size='4rem' />
        </div>
      </Modal>
    </>
  )
}
