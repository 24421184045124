import { useEffect, useState } from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { Dialog, DialogContent, DialogTitle, LinearProgress } from '@mui/material'
import { useApiGet } from '../../apiCalls/useApi'
import { getProviderDailyLogs } from '../../apiCalls/alertsApi'

type ProviderDailyLogsProps = {
  open: boolean
  onClose: () => void
  appId: string
  providerId: string
  providerName: string
  date: string
}

type DailyLogType = {
  Application: string
  Default_x0020_Program: string
  FullName: string
  MiscID: string
  Status: string
  TimeStamp: string
  TranID: string
  Type: string
}

export default function ProviderDailyLogs({
  open,
  onClose,
  appId,
  providerId,
  providerName,
  date,
}: ProviderDailyLogsProps) {
  const [loading, setLoading] = useState(false)
  const [logs, setLogs] = useState<DailyLogType[]>([])

  const fetchProviderDailyLogs = useApiGet(getProviderDailyLogs)

  const columns = [
    {
      field: 'Application',
      flex: 1,
    },
    {
      field: 'Default_x0020_Program',
      headerName: 'Default_x0020_Program',
      flex: 1.5,
    },
    {
      field: 'Type',
      headerName: 'Type',
      flex: 1.5,
    },
    {
      field: 'TimeStamp',
      headerName: 'TimeStamp',
      flex: 1.5,
    },
    {
      field: 'FullName',
      headerName: 'FullName',
      flex: 1,
    },
    {
      field: 'Status',
      headerName: 'Status',
      flex: 0.5,
    },
    {
      field: 'TranID',
      headerName: 'TranID',
      flex: 1,
    },
    {
      field: 'MiscID',
      headerName: 'MiscID',
      flex: 0.5,
    },
  ]

  useEffect(() => {
    if (!open) return
    setLoading(true)
    fetchProviderDailyLogs({
      appId,
      providerId,
      date,
    }).then((data) => {
      setLoading(false)
      if (data) {
        setLogs(data.data)
      }
    })
  }, [open, appId, providerId, date])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          width: '85vw',
          maxWidth: '85vw',
        },
      }}
    >
      <DialogTitle>
        {date} {providerName}
      </DialogTitle>
      <DialogContent>
        <DataGrid
          loading={loading}
          autoHeight
          hideFooterSelectedRowCount
          getRowId={(row) => row.TranID}
          slots={{
            loadingOverlay: LinearProgress,
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          columns={columns}
          rows={logs || []}
          sx={{
            border: 0,
            '& .MuiDataGrid-row:hover': {
              backgroundColor: 'rgba(235, 235, 235, 0.7)',
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#FFFFFF',
              color: '#5B5B5B',
              fontSize: '15px',
              fontFamily: 'Segoe UI',
              borderBottom: '2px solid #BABABA',
            },
            '& .MuiDataGrid-cell': {
              borderBottom: `1px solid #BABABA`,
              fontSize: '12px',
              cursor: 'pointer',
              fontFamily: 'Segoe UI',
              fontWeight: '600',
            },
            '& .MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            '& ::-webkit-scrollbar': {
              width: '5px',
            },
            '& ::-webkit-scrollbar-track': {
              backgroundColor: '#f1f1f1',
            },
            '& ::-webkit-scrollbar-thumb': {
              backgroundColor: '#888',
              borderRadius: '5px',
              '&:hover': {
                backgroundColor: '#555',
              },
            },
            '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
              color: '#5B5B5B',
            },
            '& .MuiDataGrid-toolbarContainer .MuiSvgIcon-root': {
              fill: '#5B5B5B',
            },
          }}
        />
      </DialogContent>
    </Dialog>
  )
}
