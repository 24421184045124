const graphStyle = (fullScreen: boolean) => {
  if (!fullScreen) {
    return {
      height: '390px',
      backgroundColor: '#FFFFFF',
      marginTop: '2px',
    }
  }
  return {
    height: 'calc(100vh - 220px)',
    backgroundColor: '#FFFFFF',
    marginTop: '2px',
  }
}

const dataStyle = {
  height: '500px',
  backgroundColor: '#FFFFFF',
  marginTop: '2px',
}

const titleStyle = {
  height: '45px',
  backgroundColor: '#FFFFFF',
  color: '#A3A3A3',
  fontWeight: '600',
  paddingLeft: '20px',
  paddingTop: '5px',
  display: 'flex',
}

const numberStyle = {
  fontSize: '17px',
  color: '#3D3D3D',
  fontWeight: '700',
  marginLeft: '20px',
}

const countRateAlertsStyle = {
  fontSize: '10px',
  color: '#A3A3A3',
  display: 'flex',
  marginLeft: '20px',
}

const countRateAlertsNumberStyle = {
  fontSize: '10px',
  color: '#3D3D3D',
  marginLeft: '20px',
  fontWeight: '700',
}

const legendStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#FFFFFF',
  height: '20px',
}

const legendItemStyle = {
  display: 'flex',
  alignItems: 'center',
  marginRight: '20px',
  color: '#5A5A5A',
  fontWeight: '700',
  fontSize: '9px',
}

const legendColorStyle = (color: string) => ({
  width: '12px',
  height: '12px',
  borderRadius: '50%',
  backgroundColor: color,
  marginRight: '5px',
})

const pieChartStyle = {
  height: '390px',
  backgroundColor: '#FFFFFF',
  marginTop: '2px',
}

const pieChartTheme = {
  legends: {
    text: {
      fontSize: '9px',
      fill: '#5A5A5A',
      fontFamily: 'Segoe UI',
      fontWeight: '700',
    },
  },
}

export {
  graphStyle,
  dataStyle,
  titleStyle,
  numberStyle,
  countRateAlertsStyle,
  countRateAlertsNumberStyle,
  legendStyle,
  legendItemStyle,
  legendColorStyle,
  pieChartStyle,
  pieChartTheme,
}
