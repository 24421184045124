import { useContext, useEffect } from 'react'
import axios from 'axios'
import Cookies from 'js-cookie'
import { Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import PlatformLayout from '../../components/PlatformLayout'
import { AWS_CONFIG } from '../../config'
import { AuthContext, getAuthData, redirectToLogin } from '../../Auth'

export default function Auth() {
  const query = new URLSearchParams(window.location.search)
  const code = query.get('code')
  const navigate = useNavigate()
  const { setAuth } = useContext(AuthContext)

  useEffect(() => {
    if (!code) {
      redirectToLogin()
    } else {
      const params = new URLSearchParams()
      params.append('grant_type', 'authorization_code')
      params.append('client_id', AWS_CONFIG.ClientId)
      params.append('code', code)
      params.append('redirect_uri', AWS_CONFIG.LoginRedirectUri)

      axios
        .post(`https://${AWS_CONFIG.CognitoDomain}/oauth2/token`, params, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        .then((response) => {
          const idToken = response.data.id_token
          setAuth(getAuthData(idToken))
          Cookies.set('auth_token', response.data.id_token)
          navigate('/')
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }, [code, navigate, setAuth])

  return (
    <PlatformLayout currentItem='Login'>
      <Grid item>
        <h1>Loading...</h1>
      </Grid>
    </PlatformLayout>
  )
}
