import SnackAlert from './SnackAlert'
import { TypeSnackAlertParams } from './ErrorSnackAlert'

export default function SuccessSnackAlert({ open, handleCloseError, text }: TypeSnackAlertParams) {
  const displayText = text ?? 'Success'
  return (
    <SnackAlert
      open={open}
      handleCloseError={handleCloseError}
      text={displayText}
      severity='success'
      autoHideDuration={3000}
    />
  )
}
