import {Alert, AlertColor, Snackbar} from '@mui/material'

type SnackAlertParams = {
  open: boolean
  handleCloseError: () => void
  text?: string
  severity: AlertColor
  autoHideDuration: number
}

export default function SnackAlert({ open, handleCloseError, text, severity, autoHideDuration }: SnackAlertParams) {
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleCloseError}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <Alert sx={{ width: '100%' }} severity={severity} onClose={handleCloseError}>
        {text}
      </Alert>
    </Snackbar>
  )
}
