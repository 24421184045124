/* eslint-disable */
import { Breadcrumbs, Link, Typography } from '@mui/material'
import { useLocation } from 'react-router-dom'
import {isUUID} from "../../utils/string";
import OnlineHelpIcon from '../OnlineHelpIcon';
import '../../globalStyle.scss'

export default function RIBreadcrumbsNav() {
  const location = useLocation()
  const pathnames = location.pathname.split('/').filter((x) => x)
    .filter((e) => !isUUID(e))

  const breadcrumbNameMap: any = {
    alerts:'Alerts',
    organizationSetting: 'Organization Setting',
    detectionRules: 'Detection Rules',
    edit: 'Edit',
    indicators: 'Indicators',
    alertDashboard:'Alert Dashboard',
    investigationDashboard:'Investigation Dashboard',
    providersResult:'Provider Result',
    providerInvestigation:'Provider Investigation',
    provider:'Provider',
    client:'Client',
    clientsResult:'Client Result',
    clientInvestigation:'Client Investigation',
    help:'Help',
    dashboardHelp:'Dashboard Help',
    upload: 'Upload',
    eubaRules:'EUBA Rules'
  }

  return (
    <div role='presentation' className='breadCrumbStyle'>
      <Breadcrumbs aria-label='breadcrumb'>
        <Link underline='hover' color='inherit' href='/'>
          Home
        </Link>

        {pathnames.map((value, index) => {
          const last = index === pathnames.length - 1
          const to = `/${pathnames.slice(0, index + 1).join('/')}`
          const name = breadcrumbNameMap[value] || value

          if (last) {
            return (
              <Typography color='textPrimary' key={value} sx={{cursor:'pointer'}}>
                {name}
              </Typography>
            );
          } else {
            return (
              <Link underline='hover' color='inherit' href={to} key={value}>
                {name}
              </Link>
            );
          }
        })}
      </Breadcrumbs>
      <OnlineHelpIcon/>
    </div>
  )
}
