import {
  Button,
  TextField,
  TextFieldProps
} from '@mui/material'
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";

const dateInputStyle = {
  svg: { fontSize: '18px' },
  '.MuiInputLabel-root': {
    color: '#5B5B5B',
  },
  '.MuiInputBase-input': {
    color: '#5B5B5B',
  },
  '.MuiOutlinedInput-root': {
    height: '36px',
    width: '190px',
    marginRight: '5px'
  },
}

function DateRangePicker({fromDate, setFromDate, toDate, setToDate, submit}: any) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '10px',
          }}
        >
          <DatePicker
            label='From'
            value={fromDate}
            onChange={(newValue: Date | null) => setFromDate(newValue)}
            renderInput={(params: TextFieldProps) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <TextField {...params} sx={dateInputStyle} />
            )}
          />
          <DatePicker
            label='To'
            value={toDate}
            onChange={(newValue: Date | null) => setToDate(newValue)}
            renderInput={(params: TextFieldProps) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <TextField {...params} sx={dateInputStyle} />
            )}
          />
          <Button type='submit' variant='contained' onClick={submit} className='buttonEnable'>
            Submit
          </Button>
        </div>
      </div>
    </LocalizationProvider>
  )
}

export default DateRangePicker
