/* eslint-disable */
import React from 'react'
import {useParams} from "react-router-dom";
import PlatformLayout from '../../components/PlatformLayout'
import AddProviderAndClient from './AddProviderAndClient'
import { Paper } from '@mui/material'
import '../../globalStyle.scss'
import ExceptionList from './ExceptionList'
import VipList from './VipList'
import { useLocation } from 'react-router-dom'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export default function SettingDetails() {
  let query = useQuery()
  let type = query.get('type')

  const { id } = useParams<{ id: string }>();
  const ruleId = id || ''

  if (type === 'AccessSelf')
    return (
      <PlatformLayout currentItem='Detection Rules'>
        <Paper
          square
          elevation={0}
          className='paperStyle'
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
          }}
        >
          <div className='ruleSettingDetailsTitle'> Provider Access Their Own Records</div>
          <Paper
            square
            elevation={0}
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              height: 'calc(100vh - 212px)',
              overflow: 'auto',
            }}
          >
            <AddProviderAndClient />
            <ExceptionList ruleId={ruleId} />
          </Paper>
        </Paper>
      </PlatformLayout>
    )

  if (type === 'AccessInternal')
    return (
      <PlatformLayout currentItem=''>
        <Paper
          square
          elevation={0}
          className='paperStyle'
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
          }}
        >
          <div className='ruleSettingDetailsTitle'>Provider Access Internal Staff's Records</div>
          <Paper
            square
            elevation={0}
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              height: 'calc(100vh - 212px)',
              overflow: 'auto',
            }}
          >
            <AddProviderAndClient />
            <ExceptionList ruleId={ruleId} />
          </Paper>
        </Paper>
      </PlatformLayout>
    )

  if (type === 'AccessVIP')
    return (
      <PlatformLayout currentItem=''>
        <Paper
          square
          elevation={0}
          className='paperStyle'
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
          }}
        >
          <div className='ruleSettingDetailsTitle'>Provider Access VIP's Records</div>
          <Paper
            square
            elevation={0}
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              height: 'calc(100vh - 212px)',
              overflow: 'auto',
            }}
          >
            <VipList ruleId={ruleId}/>
            <ExceptionList ruleId={ruleId} />
          </Paper>
        </Paper>
      </PlatformLayout>
    )

  if (type === 'AccessRelatives')
    return (
      <PlatformLayout currentItem=''>
        <Paper
          square
          elevation={0}
          className='paperStyle'
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
          }}
        >
          <div className='ruleSettingDetailsTitle'>Provider Access Relative's Records</div>
          <Paper
            square
            elevation={0}
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              height: 'calc(100vh - 212px)',
              overflow: 'auto',
            }}
          >
            <ExceptionList ruleId={ruleId} />
          </Paper>
        </Paper>
      </PlatformLayout>
    )

  if (type === 'AccessFamily')
    return (
      <PlatformLayout currentItem=''>
        <Paper
          square
          elevation={0}
          className='paperStyle'
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
          }}
        >
          <div className='ruleSettingDetailsTitle'>Provider Access Family Member's Records</div>
          <Paper
            square
            elevation={0}
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              height: 'calc(100vh - 212px)',
              overflow: 'auto',
            }}
          >
            <ExceptionList ruleId={ruleId} />
          </Paper>
        </Paper>
      </PlatformLayout>
    )

  if (type === 'AccessNeighbors')
    return (
      <PlatformLayout currentItem=''>
        <Paper
          square
          elevation={0}
          className='paperStyle'
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
          }}
        >
          <div className='ruleSettingDetailsTitle'>Provider Access Neighbour's Records</div>
          <Paper
            square
            elevation={0}
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              height: 'calc(100vh - 212px)',
              overflow: 'auto',
            }}
          >
            <ExceptionList ruleId={ruleId} />
          </Paper>
        </Paper>
      </PlatformLayout>
    )

  return <div>error</div>
}
