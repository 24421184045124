/* eslint-disable */
import {  useState } from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { LinearProgress } from '@mui/material'
import InvestigationPageAccessLog from './InvestigationPageAccessLog'
import { useNavigate } from 'react-router-dom'

export default function AccessList({ type, data, isLoading}: any) {  
  const [accessLogsDialogOpen, setAccessLogsDialogOpen] = useState<boolean>(false)
  const [accessLogs, setAccessLogs] = useState([])
  const navigation = useNavigate()

  const handleAccessLogs = async (id: string) => {
    setAccessLogsDialogOpen(true)
    if (type === 'Provider') {
      const filteredData = data.filter((item: any) => item.clientId === id)
      setAccessLogs(filteredData[0].filteredLogs)
    } else {
      const filteredData = data.filter((item: any) => item.providerId === id)
      setAccessLogs(filteredData[0].filteredLogs)
    }
  }

  const columns = [
    {
      field: type === 'Client' ? `providerId` : `clientId`,
      headerName: type === 'Client' ? `Provider ID` : `Client ID`,
      flex: 1,
      renderCell: (params: any) => (
        <div
          className='linkStyle'
          onClick={() => {
            toInvestigationPage(params.field, params.value)
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: type === 'Client' ? `providerName` : `clientName`,
      headerName: type === 'Client' ? `Provider Name` : `Client Name`,
      flex: 1,
    },
    {
      field: 'first',
      headerName: 'First Access Time',
      flex: 1,
    },
    {
      field: 'last',
      headerName: 'Last Access Time',
      flex: 1,
    },
    {
      field: 'count',
      headerName: 'Access Counts',
      flex: 1,
      renderCell: (params: any) => (
        <div
          className='linkStyle'
          style={{ width: '100px' }}
          onClick={() => {
            handleAccessLogs(type === 'Provider' ? params.row.clientId : params.row.providerId)
          }}
        >
          {params.row.count}
        </div>
      ),
    },
  ]

  const handleAccessLogsDialogClose = () => {
    setAccessLogsDialogOpen(false)
  }

  const toInvestigationPage = (field: string, id: string) => {
    if (field === 'clientId') {
      navigation(`/client/clientInvestigation`, { state: { searchID: id } })
    } else {
      navigation(`/provider/providerInvestigation`, { state: { searchID: id } })
    }
  }

  const rowID = (row: any) => {
    if (type === 'Client') {
      return row.providerId
    }
    return row.clientId
  }

  return (
    <div style={{ height: 'calc(100% - 42px)' }}>
      <DataGrid
        hideFooter
        sx={{
          border: 0,
          '& .MuiDataGrid-row:hover': {
            backgroundColor: 'rgba(235, 235, 235, 0.7)',
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#FFFFFF',
            color: '#5B5B5B',
            fontSize: '15px',
            fontFamily: 'Segoe UI',
            borderBottom: '2px solid #BABABA',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: `1px solid #BABABA`,
            color: '#000000',
            fontSize: '12px',
            fontFamily: 'Segoe UI',
            cursor: 'pointer',
            fontWeight: '600',
          },
          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& ::-webkit-scrollbar': {
            width: '5px',
          },
          '& ::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1',
          },
          '& ::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '5px',
            '&:hover': {
              backgroundColor: '#555',
            },
          },
          '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
            color: '#5B5B5B',
          },
          '& .MuiDataGrid-toolbarContainer .MuiSvgIcon-root': {
            fill: '#5B5B5B',
          },
        }}
        columns={columns}
        rows={data || []}
        getRowId={(row) => rowID(row)}
        loading={isLoading}
        slots={{
          toolbar: GridToolbar,
          loadingOverlay: LinearProgress,
        }}
      />
      <InvestigationPageAccessLog
        accessLogs={accessLogs}
        accessLogsDialogOpen={accessLogsDialogOpen}
        accessLogsDialogClose={handleAccessLogsDialogClose}
      />
    </div>
  )
}
