/* eslint-disable */
import { Box, Button, Tab, Tabs } from '@mui/material'
import '../../globalStyle.scss'
import React, { useContext, useEffect, useState } from 'react'
import DateFilter from './DateFilter'
import dayjs from 'dayjs'
import DateRangePicker from '../../components/DateRangePicker'
import AccessList from './AccessList'
import AccessChart from './AccessChart'
import TimeLine from './TimeLine'
import { useApiGet } from '../../apiCalls/useApi'
import {
  getClientAccessCount,
  getClientAccessLogs,
  getProviderAccessCount,
  getProviderAccessLogs,
} from '../../apiCalls/alertsApi'
import { OrgContext } from '../../Org'

export default function InvestigationContent({ type, searchID }: any) {
  const { org } = useContext(OrgContext)
  const appId = org.apps?.[0]?.id
  const [mode, setMode] = useState<string>('accessTimesTable')
  const handleChangeMode = (event: React.SyntheticEvent, newValue: string) => {
    setMode(newValue)
  }

  const [fromDate, setFromDate] = useState<Date | null>(
    dayjs().subtract(3, 'months').startOf('day').toDate()
  )
  const [accessListData, setAccessListData] = useState<any>([])
  const [timelineData, setTimelineData] = useState([])
  const [nodes, setNodes] = useState<any>([])
  const [edges, setEdges] = useState([])
  const [toDate, setToDate] = useState<Date | null>(dayjs().endOf('day').toDate())
  const [isCustomDate, setIsCustomDate] = useState<boolean>(false)
  const defaultEndDate = dayjs().endOf('day').toDate()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [sidebarVisible, setSidebarVisible] = useState<boolean>(false)
  const [info, setInfo] = useState<string>('')
  const [graphType, setGraphType] = useState<string>('concentric')
  const fetchProviderAccessLogs = useApiGet(getProviderAccessLogs)
  const fetchClientAccessLogs = useApiGet(getClientAccessLogs)
  const fetchProviderAccessCount = useApiGet(getProviderAccessCount)
  const fetchClientAccessCount = useApiGet(getClientAccessCount)

  useEffect(()=>{
    handleSubmit()
  },[])

  const handleGraphTypeChange = (gType: string) => {
    setGraphType(gType)
  }

  const handleSubmit = async () => {
    handleDateSubmitForList()
    handleDateSubmitForChart()
    handleDateSubmitForTimeline()
  }

  const handleDateSubmitForList = async () => {
    if (fromDate && dayjs(fromDate).isValid() && toDate && dayjs(toDate).isValid()) {
      const fromDateTimestamp = dayjs(fromDate).valueOf()
      const toDateTimestamp = dayjs(toDate).valueOf()
      if (fromDateTimestamp > toDateTimestamp) {
        return
      }
      if (type === 'Provider') {
        setIsLoading(true)
        const response = await fetchProviderAccessCount({
          appId,
          providerId: searchID,
          startTime: fromDateTimestamp,
          endTime: toDateTimestamp,
        })
        if (!response || !response.data || response.error) return
        const { data, error } = response
        if (response && !error && data) {
          processData(searchID, data, fromDateTimestamp, toDateTimestamp, 'Provider')
          setIsLoading(false)
        }
      } else {
        setIsLoading(true)
        const response = await fetchClientAccessCount({
          appId,
          clientId: searchID,
          startTime: fromDateTimestamp,
          endTime: toDateTimestamp,
        })
        if (!response || !response.data || response.error) return
        const { data, error } = response
        if (response && !error && data) {
          processData(searchID, data, fromDateTimestamp, toDateTimestamp, 'Client')
          setIsLoading(false)
        }
      }
    }
  }

  const processData = async (
    id: string,
    rawData: any,
    start: number,
    end: number,
    role: string
  ) => {
    if (role === 'Provider') {
      const getLogs = await fetchProviderAccessLogs({
        appId: appId,
        providerId: id,
        startTime: start,
        endTime: end,
      })
      if (!getLogs || !getLogs.data || getLogs.error) return
      const { data: logs } = getLogs
      const afterProcess = rawData.map((item: any) => {
        const filteredLogs = logs.filter((log: any) => log.clientId === item.clientId)
        const sortedLogs = filteredLogs.sort((a: any, b: any) =>
          dayjs(a.datetime).isAfter(dayjs(b.datetime)) ? 1 : -1
        )
        const first = dayjs(sortedLogs[0].datetime).format('YYYY-MM-DD HH:mm:ss')
        const last = dayjs(sortedLogs[sortedLogs.length - 1].datetime).format('YYYY-MM-DD HH:mm:ss')
        return { ...item, filteredLogs, first, last }
      })

      setAccessListData(afterProcess)
    } else {
      const getLogs = await fetchClientAccessLogs({
        appId: appId,
        clientId: id,
        startTime: start,
        endTime: end,
      })
      if (!getLogs || !getLogs.data || getLogs.error) return
      const { data: logs } = getLogs
      const afterProcess = rawData.map((item: any) => {
        const filteredLogs = logs.filter((log: any) => log.providerId === item.providerId)
        const sortedLogs = filteredLogs.sort((a: any, b: any) =>
          dayjs(a.datetime).isAfter(dayjs(b.datetime)) ? 1 : -1
        )
        const first = dayjs(sortedLogs[0].datetime).format('YYYY-MM-DD HH:mm:ss')
        const last = dayjs(sortedLogs[sortedLogs.length - 1].datetime).format('YYYY-MM-DD HH:mm:ss')
        return { ...item, filteredLogs, first, last }
      })
      setAccessListData(afterProcess)
    }
  }

  const handleDateSubmitForChart = async () => {
    setInfo('')
    setSidebarVisible(false)
    if (fromDate && dayjs(fromDate).isValid() && toDate && dayjs(toDate).isValid()) {
      const fromDateTimestamp = dayjs(fromDate).valueOf()
      const toDateTimestamp = dayjs(toDate).valueOf()
      if (fromDateTimestamp > toDateTimestamp) {
        return
      }
      if (type === 'Provider') {
        setIsLoading(true)
        const response = await fetchProviderAccessCount({
          appId,
          providerId: searchID,
          startTime: fromDateTimestamp,
          endTime: toDateTimestamp,
        })
        if (!response || !response.data || response.error) return
        setIsLoading(false)
        if (response.data.length === 0) {
          setInfo('no data')
          setNodes([])
          setEdges([])
          return
        }
        const processingNodes: any = [
          {
            data: {
              id: searchID,
              label: `${response.data[0].providerName} (${searchID})`,
            },
            classes: 'mainNode',
          },
        ]
        const processingEdges: any = []
        response.data.forEach((item: any) => {
          const label = `${item.clientName} (${item.clientId})`
          processingNodes.push({
            data: {
              id: item.clientId,
              label,
            },
          })
          processingEdges.push({
            data: {
              source: searchID,
              target: item.clientId,
              label: item.count,
            },
          })
        })
        setSidebarVisible(true)
        setNodes(processingNodes)
        setEdges(processingEdges)
      } else {
        setIsLoading(true)
        const response = await fetchClientAccessCount({
          appId,
          clientId: searchID,
          startTime: fromDateTimestamp,
          endTime: toDateTimestamp,
        })
        if (!response || !response.data || response.error) return
        setIsLoading(false)
        if (response.data.length === 0) {
          setInfo('no data')
          setNodes([])
          setEdges([])
          return
        }
        const processingNodes: any = [
          {
            data: {
              id: searchID,
              label: `${response.data[0].clientName} (${searchID})`,
            },
            classes: 'mainNode',
          },
        ]
        const processingEdges: any = []
        response.data.forEach((item: any) => {
          const label = `${item.providerName} (${item.providerId})`
          processingNodes.push({
            data: {
              id: item.providerId,
              label,
            },
          })
          processingEdges.push({
            data: {
              source: searchID,
              target: item.providerId,
              label: item.count,
            },
          })
        })
        setSidebarVisible(true)
        setNodes(processingNodes)
        setEdges(processingEdges)
      }
    }
  }

  const handleDateSubmitForTimeline = async () => {
    if (fromDate && dayjs(fromDate).isValid() && toDate && dayjs(toDate).isValid()) {
      const fromDateTimestamp = dayjs(fromDate).valueOf()
      const toDateTimestamp = dayjs(toDate).valueOf()
      if (fromDateTimestamp > toDateTimestamp) {
        return
      }
      if (type === 'Provider') {
        setIsLoading(true)
        const response = await fetchProviderAccessCount({
          appId,
          providerId: searchID,
          startTime: fromDateTimestamp,
          endTime: toDateTimestamp,
        })
        if (!response || !response.data || response.error) return
        const { data, error } = response
        if (response && !error && data) {
          processDataForTimeline(
            searchID,
            data,
            fromDateTimestamp,
            toDateTimestamp,
            'Provider'
          )
        }
      } else {
        setIsLoading(true)
        const response = await fetchClientAccessCount({
          appId,
          clientId: searchID,
          startTime: fromDateTimestamp,
          endTime: toDateTimestamp,
        })
        if (!response || !response.data || response.error) return
        const { data, error } = response
        if (response && !error && data) {
          processDataForTimeline(searchID, data, fromDateTimestamp, toDateTimestamp, 'Client')
        }
      }
    }
  }

  const processDataForTimeline = async (
    id: string,
    rawData: any,
    start: number,
    end: number,
    role: string
  ) => {
    if (role === 'Provider') {
      const getLogs = await fetchProviderAccessLogs({
        appId: appId,
        providerId: id,
        startTime: start,
        endTime: end,
      })
      if(!getLogs || !getLogs.data || getLogs.error) return
      setIsLoading(false)
      const { data: logs } = getLogs
      const afterProcess = rawData.map((item: any) => {
        const filteredLogs = logs.filter((log: any) => log.clientId === item.clientId)
        const sortedLogs = filteredLogs.sort((a: any, b: any) =>
          dayjs(a.datetime).isAfter(dayjs(b.datetime)) ? 1 : -1
        )
        const first = dayjs(sortedLogs[0].datetime).format('YYYY-MM-DD HH:mm:ss')
        const last = dayjs(sortedLogs[sortedLogs.length - 1].datetime).format('YYYY-MM-DD HH:mm:ss')
        return { ...item, filteredLogs, first, last }
      })
      setTimelineData(afterProcess)
    } else {
      const getLogs = await fetchClientAccessLogs({
        appId: appId,
        clientId: id,
        startTime: start,
        endTime: end,
      })
      if(!getLogs || !getLogs.data || getLogs.error) return
      setIsLoading(false)
      const { data: logs } = getLogs
      const afterProcess = rawData.map((item: any) => {
        const filteredLogs = logs.filter((log: any) => log.providerId === item.providerId)
        const sortedLogs = filteredLogs.sort((a: any, b: any) =>
          dayjs(a.datetime).isAfter(dayjs(b.datetime)) ? 1 : -1
        )
        const first = dayjs(sortedLogs[0].datetime).format('YYYY-MM-DD HH:mm:ss')
        const last = dayjs(sortedLogs[sortedLogs.length - 1].datetime).format('YYYY-MM-DD HH:mm:ss')
        return { ...item, filteredLogs, first, last }
      })
      setTimelineData(afterProcess)
    }
  }

  return (
    <div className='tableAndChartContainer'>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          borderRadius: '5px 5px 0 0',
          backgroundColor: 'white',
          width: 'fit-content',
        }}
      >
        <Tabs value={mode} onChange={handleChangeMode}>
          <Tab label='Access List' value='accessTimesTable' />
          <Tab label='Access Chart' value='accessTimesChart' />
          <Tab label='Access Timeline' value='accessTimeLine' />
        </Tabs>
      </Box>
      <div className='accessTimesContent'>
        <Box
          sx={{
            backgroundColor: 'white',
            width: '100%',
            paddingLeft: '2px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
            <DateFilter
              setStartDate={setFromDate}
              setEndDate={setToDate}
              defaultEndDate={defaultEndDate}
              submit={handleSubmit}
              setIsCustomDate={setIsCustomDate}
              setIsLoading={setIsLoading}
              isCustomDate={isCustomDate}
            />
            {isCustomDate && (
              <DateRangePicker
                fromDate={fromDate}
                setFromDate={setFromDate}
                toDate={toDate}
                setToDate={setToDate}
                submit={handleSubmit}
              />
            )}
          </div>
          <div
            style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', flex: 1 }}
          >
            {mode === 'accessTimesChart' && (
              <div style={{display:'flex'}}>
                <Button
                  variant='contained'
                  size='medium'
                  sx={{marginRight:'5px'}}
                  onClick={() => {
                    handleGraphTypeChange('concentric')
                  }}
                  className={`changeGraphButton ${
                    graphType === 'concentric' ? 'changeGraphButtonActive' : ''
                  }`}
                >
                  Concentric
                </Button>

                <Button
                  variant='contained'
                  size='medium'
                  sx={{marginRight:'5px'}}
                  onClick={() => {
                    handleGraphTypeChange('circle')
                  }}
                  className={`changeGraphButton ${
                    graphType === 'circle' ? 'changeGraphButtonActive' : ''
                  }`}
                >
                  Circle
                </Button>

                <Button
                  variant='contained'
                  size='medium'
                  sx={{marginRight:'5px'}}
                  onClick={() => {
                    handleGraphTypeChange('breadthfirst')
                  }}
                  className={`changeGraphButton ${
                    graphType === 'breadthfirst' ? 'changeGraphButtonActive' : ''
                  }`}
                >
                  Breadth First
                </Button>
              </div>
            )}
          </div>
        </Box>

        {mode === 'accessTimesTable' && (
          <AccessList type={type} data={accessListData} isLoading={isLoading} />
        )}

        {mode === 'accessTimesChart' && (
          <AccessChart
            type={type}
            nodes={nodes}
            edges={edges}
            sidebarVisible={sidebarVisible}
            setSidebarVisible={setSidebarVisible}
            isLoading={isLoading}
            info={info}
            graphType={graphType}
          />
        )}

        {mode === 'accessTimeLine' && <TimeLine type={type} timelineData={timelineData} isLoading={isLoading} />}
      </div>
    </div>
  )
}
