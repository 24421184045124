/* eslint-disable */
import { IconButton } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useLocation } from 'react-router-dom';

const OnlineHelpIcon = () => {
  const location = useLocation();

  const helpRoutesMap: { [key: string]: string } = {
    '/': '/help/dashboardHelp',
    '/alerts':'/help/alertsHelp',
    '/indicators':'/help/indicatorsHelp',
    '/provider':'/help/providerSearchHelp',
    '/provider/providerInvestigation':'/help/providerInvestigationHelp',
    '/client':'/help/clientSearchHelp',
    '/client/clientInvestigation':'/help/clientInvestigationHelp',
    '/organizationSetting':'/help/orgSettingHelp',
    '/detectionRules':'/help/ruleSettingHelp'
  };

  const currentPath = location.pathname;
  const helpPath = helpRoutesMap[currentPath];

  const handleHelpClick = () => {
    if (helpPath) {
      window.open(helpPath)
    } else {
      alert('No help available for this page');
    }
  };

  const showHelpIcon = Object.keys(helpRoutesMap).includes(currentPath);

  if (!showHelpIcon) {
    return null;
  }

  return (
    <IconButton aria-label="help" onClick={handleHelpClick}>
      <HelpOutlineIcon />
    </IconButton>
  );
};

export default OnlineHelpIcon;
