import { Button } from '@mui/material'
import '../../globalStyle.scss'
import { GiPlainArrow } from 'react-icons/gi'

export default function DetailsSideBar({ sidebarVisible, hoverInfo, type }: any) {
  return (
    <div
      className='accessChartSideBarContainer'
      style={{
        right: sidebarVisible ? '0' : '-300px',
      }}
    >
      {/* sidebar content */}
      <div className='accessChartSideBarContent'>
        <div>
          <h3>Access Details</h3>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div style={{ paddingTop: '100px' }}>
            <Button
              sx={{
                backgroundColor: '#1665C0',
                color: '#FFFFFF',
                minWidth: '260px',
                minHeight: '80px',
                '&:hover': {
                  backgroundColor: '#1665C0',
                },
              }}
            >
              {type === 'Provider' ? `${hoverInfo.centerNode}` : `${hoverInfo.hoveredNode}`}
            </Button>
            <div
              style={{
                marginLeft: '100px',
                marginTop: '40px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <GiPlainArrow style={{ color: '#1665C0' }} size={50} />
              <div style={{ marginLeft: '8px',fontWeight:'600'}}>
                {`${hoverInfo.accessCount}`}
              </div>
            </div>
          </div>

          <div style={{ marginTop: '40px' }}>
            <Button
              sx={{
                backgroundColor: '#1665C0',
                color: '#FFFFFF',
                minWidth: '260px',
                minHeight: '80px',
                '&:hover': {
                  backgroundColor: '#1665C0',
                },
              }}
            >
              {type === 'Provider' ? `${hoverInfo.hoveredNode}` : `${hoverInfo.centerNode}`}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
