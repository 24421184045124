/* eslint-disable */
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import '../../globalStyle.scss'
import { IconButton } from '@mui/material'
import PersonAddIcon from '@mui/icons-material/PersonAdd';

export default function PersonList({
  type,
  data,
  setChosenProvider,
  setChosenClient,
  onClose,
}: any) {
  const handleSelect = (row: any) => {
    return () => {
      submit(row)
    }
  }

  const submit = (row: any) => {
    if (row.id === '') return
    if (type === 'Provider') {
      setChosenProvider(row)
    } else if (type === 'Client') {
      setChosenClient(row)
    }
    onClose()
  }

  const columns = [
    {
      field: 'Select',
      renderCell: (params: any) => (
        <IconButton
          style={{
            color: '#1876D1',
          }}
          onClick={handleSelect(params.row)}
        >
          <PersonAddIcon />
        </IconButton>
      ),
    },
    {
      field: 'id',
      headerName: type === 'Provider' ? 'Provider Id' : 'Client Id',
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'firstAccessTime',
      headerName: 'First Access Time',
      flex: 1,
      renderCell: (params: any) => {
        return dayjs(params.value).format('YYYY-MM-DD HH:mm:ss')
      },
    },
    {
      field: 'lastAccessTime',
      headerName: 'Last Access Time',
      flex: 1,
      renderCell: (params: any) => {
        return dayjs(params.value).format('YYYY-MM-DD HH:mm:ss')
      },
    },
  ]

  return (
    <div
      style={{
        marginTop: '10px',
        paddingRight: '10px',
        height: 'calc(100vh - 200px)',
        backgroundColor: '#FFFFFF',
        width: '98.5%',
        borderRadius: '5px',
      }}
    >
      <DataGrid
        slots={{
          toolbar: GridToolbar,
        }}
        // hideFooter
        sx={{
          border: 0,
          '& .MuiDataGrid-row:hover': {
            backgroundColor: 'rgba(235, 235, 235, 0.7)',
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#FFFFFF',
            color: '#5B5B5B',
            fontSize: '15px',
            fontFamily: 'Segoe UI',
            borderBottom: '2px solid #BABABA',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: `1px solid #BABABA`,
            color: '#000000',
            fontSize: '12px',
            fontFamily: 'Segoe UI',
            cursor: 'pointer',
            fontWeight: '600',
            // backgroundColor: '#FFFFFF',
          },
          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& ::-webkit-scrollbar': {
            width: '5px',
          },
          '& ::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1',
          },
          '& ::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '5px',
            '&:hover': {
              backgroundColor: '#555',
            },
          },
          '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
            color: '#5B5B5B',
          },
          '& .MuiDataGrid-toolbarContainer .MuiSvgIcon-root': {
            fill: '#5B5B5B',
          },
        }}
        columns={columns}
        rows={data || []}
      />
    </div>
  )
}
