import { useEffect, useState } from 'react'
import { Paper, Typography } from '@mui/material'
import PlatformLayout from '../../components/PlatformLayout'
import { getUploadLogs } from '../../apiCalls/uploadApi'
import { useApiGet } from '../../apiCalls/useApi'
import UploadLogList from './UploadLogList'
import UploadLog from './UploadLog'

export default function Upload() {
  const [isLoading, setIsLoading] = useState(true)
  const [uploadLogs, setUploadLogs] = useState([])

  const fetchUploadLogs = useApiGet(getUploadLogs)

  function fetchData() {
    setIsLoading(true)
    fetchUploadLogs().then((res:any) => {
      if(!res || !res.data || res.error) return
      const {data} = res
      setUploadLogs(data)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <PlatformLayout currentItem='Upload'>
      <Paper
        square
        elevation={0}
        className='paperStyle'
        style={{
          width: '100%',
          borderRadius:'5px'
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          <UploadLog reload={fetchData} />
        </div>
        <div style={{ paddingTop: '8px' }}>
          <Typography variant='h6'>History</Typography>
        </div>
        <UploadLogList isLoading={isLoading} data={uploadLogs} />
      </Paper>
    </PlatformLayout>
  )
}
