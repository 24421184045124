import { Grid } from '@mui/material'
import RIAppBar from '../../components/AppBar'

export default function HelpPageLayout({ children }: any) {
  return (
    <div
      style={{
        boxSizing: 'border-box',
        position: 'relative',
        overflowX: 'hidden',
        width: '100%',
      }}
    >
      <div
        style={{
          position: 'fixed',
          width: '100%',
          top: '0',
          zIndex: 9999,
        }}
      >
        <RIAppBar />
      </div>
      <Grid container>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            marginTop: '53px',
            display: 'flex',
            justifyContent: 'center',
            minHeight: '80vh',
          }}
        >
          {children}
        </Grid>
      </Grid>
    </div>
  )
}
