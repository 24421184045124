import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material'

type ConfirmParams = {
  isOpen: boolean
  warning?: string
  text: string
  handleConfirm: () => void
  handleClose: () => void
}

export default function Confirm({
  isOpen,
  text,
  handleConfirm,
  handleClose,
  warning,
}: ConfirmParams) {
  return (
    <Dialog open={isOpen} onClose={handleClose} classes={{ paper: 'operateRuleListModal' }}>
      <DialogTitle>Confirm</DialogTitle>
      <DialogContent>
        <>
          {warning != null && <Typography variant='body1' style={{color:'red', paddingBottom: '6px'}}>Note: {warning}</Typography> }
          <div>{text}</div>
        </>
      </DialogContent>
      <DialogActions>
        <Button style={{ marginLeft: '10px' }} onClick={handleClose} color='inherit'>
          Cancel
        </Button>
        <Button onClick={handleConfirm}>Confirm</Button>
      </DialogActions>
    </Dialog>
  )
}
