/* eslint-disable */
import { Button, Dialog, DialogActions, TextField } from '@mui/material'
import React, { ChangeEvent, useContext, useState } from 'react'
import '../../globalStyle.scss'
import InvestigationMessage from '../InvestigationPage/InvestigationMessage'
import { searchClient, searchProvider } from '../../apiCalls/searchApi'
import PersonList from './PersonList'
import { OrgContext } from '../../Org'
import { useApiGet } from '../../apiCalls/useApi'

export default function SearchModal({
  open,
  setOpen,
  type,
  setChosenProvider,
  setChosenClient,
}: any) {
  const [isSearching, setIsSearching] = useState(false)
  const [message, setMessage] = useState<string>('Please input information')
  const [hasSearched, setHasSearched] = useState(false)
  const [searchID, setSearchID] = useState<string>('')
  const [firstname, setFirstname] = useState<string>('')
  const [lastname, setLastname] = useState<string>('')
  const [searchedPersonData, setSearchedPersonData] = useState([])
  const [canPersonListOpen, setCanPersonListOpen] = useState(false)

  const handleSearchId = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchID(event.target.value)
  }
  const handleFirstname = (event: ChangeEvent<HTMLInputElement>) => {
    setFirstname(event.target.value)
  }

  const handleLastname = (event: ChangeEvent<HTMLInputElement>) => {
    setLastname(event.target.value)
  }
  const fetchProvider = useApiGet(searchProvider)
  const fetchClient = useApiGet(searchClient)

  const { org } = useContext(OrgContext)
  const appId = org.apps?.[0]?.id

  const onSearch = async () => {
    if ((searchID !== '' || firstname !== '' || lastname !== '') && type) {
      // resetPage()
      setIsSearching(true)
      if (type === 'Provider') {
        const providerResult = await fetchProvider({
          appId: appId,
          id: searchID,
          firstName: firstname,
          lastName: lastname,
        })
        if (!providerResult || !providerResult.data || providerResult.error) return
        const { data } = providerResult
        setSearchedPersonData(data)
        setCanPersonListOpen(true)
        setIsSearching(false)
        setHasSearched(true)
      } else {
        const clientResult = await fetchClient({
          appId: appId,
          id: searchID,
          firstName: firstname,
          lastName: lastname,
        })
        if (!clientResult || !clientResult.data || clientResult.error) return
        const {data} = clientResult
        setSearchedPersonData(data)
        setCanPersonListOpen(true)
        setIsSearching(false)
        setHasSearched(true)
      }
    }
  }

  const onClose = () => {
    setOpen(false)
    setIsSearching(false)
    setSearchID('')
    setFirstname('')
    setLastname('')
    setHasSearched(false)
    setMessage('Please input information')
  }

  const dialogStyle = {
    width: '90vw',
    height: '92vh',
    maxWidth: '90vw',
    maxHeight: '92vh',
    margin: 'auto',
    backgroundColor: '#FFFFFF',
    borderRadius: '10px',
  }

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ style: dialogStyle }}>
      <div className='searchModalContainer'>
        <div
          style={{
            width: '220px',
            fontWeight: '600',
            height: '33px',
            marginRight: '10px',
            textAlign: 'center',
            lineHeight: '33px',
          }}
        >
          {type === 'Provider' ? 'Provider' : 'Client'}
        </div>

        <TextField
          variant='filled'
          label='Id'
          size='small'
          onChange={handleSearchId}
          sx={{ '& .MuiInputBase-input': { backgroundColor: '#FFFFFF' }, marginRight: '6px' }}
          value={searchID}
        />

        <TextField
          variant='filled'
          label='First Name'
          size='small'
          onChange={handleFirstname}
          sx={{ '& .MuiInputBase-input': { backgroundColor: '#FFFFFF' }, marginRight: '6px' }}
          value={firstname}
        />

        <TextField
          variant='filled'
          label='Last Name'
          size='small'
          onChange={handleLastname}
          sx={{ '& .MuiInputBase-input': { backgroundColor: '#FFFFFF' } }}
          value={lastname}
        />

        <Button
          variant='contained'
          className='buttonEnable'
          onClick={onSearch}
          style={{ marginLeft: '10px' }}
        >
          Search
        </Button>
      </div>
      {(!hasSearched || isSearching) && (
        <InvestigationMessage message={message} isSearching={isSearching} />
      )}

      {hasSearched && !isSearching && canPersonListOpen && (
        <div>
          <PersonList
            type={type}
            data={searchedPersonData}
            setChosenProvider={setChosenProvider}
            setChosenClient={setChosenClient}
            onClose={onClose}
          />
        </div>
      )}
    </Dialog>
  )
}
