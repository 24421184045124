import { createContext } from 'react'
import { jwtDecode } from 'jwt-decode'
import { AWS_CONFIG } from './config'

interface AuthContextType {
  auth: {
    email: string
    orgId: string
    token: string
  } | null
  setAuth: any
}

export const AuthContext = createContext<AuthContextType>({
  auth: {
    email: '',
    orgId: '',
    token: '',
  },
  setAuth: () => {},
})

export function redirectToLogin() {
  window.location.href = `https://${AWS_CONFIG.CognitoDomain}/login?response_type=code&client_id=${AWS_CONFIG.ClientId}&redirect_uri=${AWS_CONFIG.LoginRedirectUri}`
}

export function redirectToLogout() {
  window.location.href = `https://${AWS_CONFIG.CognitoDomain}/logout?response_type=code&client_id=${AWS_CONFIG.ClientId}&redirect_uri=${AWS_CONFIG.LoginRedirectUri}`
}

export interface JwtPayload {
  email: string
  'custom:org_id': string
  'custom:role': string
}

export function getAuthData(token: string) {
  const decoded: JwtPayload = jwtDecode(token)
  return {
    email: decoded.email,
    orgId: decoded['custom:org_id'],
    role: decoded['custom:role'],
  }
}
