import ReactMarkdown from 'react-markdown'
import './onlinehelp.scss'
import { useEffect, useState } from 'react'
import { CircularProgress } from '@mui/material'
import getOnlineHelpContent from '../../apiCalls/onlineHelpApi'
import { useApiGet } from '../../apiCalls/useApi'


export default function HelpContent({ helpID }: any) {
  const [md, setMd] = useState('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const fetchHelpContent = useApiGet(getOnlineHelpContent)

  useEffect(() => {
    setIsLoading(true)
    fetchHelpContent({ helpID }).then((res: any) => {
      if (res?.data) {
        setMd(res.data.content)
        setIsLoading(false)
      }

    })
  }, [helpID])
  return (
    <div className='online-help'>
       {isLoading && <CircularProgress sx={{ color: '#A4A4A4' }} size='4rem' className="loading-spinner"/>} 
      <ReactMarkdown>{md}</ReactMarkdown>
    </div>
  )
}
