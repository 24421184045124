import api from './api'
import apiPost, { apiPost2 } from './apiPost'

export const fetchAlertsCount = async (): Promise<any> => {
  const response = await api.post('get-alerts-count')
  return response.data
}

export const fetchAlerts = apiPost2('get-alerts')

export const fetchAlert = apiPost2('get-alert')

export const updateAlertStatus = apiPost2('update-alert-status')

export const getProviderAccessCount = apiPost2('get-provider-clients')

export const getClientAccessCount = apiPost2('get-client-providers')

export const fetchAccessLogs = apiPost2('get-logs')

export const fetchProvider = apiPost2('get-provider')

export const fetchClient = apiPost2('get-client')

// export const fetchDashboard = async (): Promise<any> => {
//   const response = await api.post('/get-dashboard')
//   return response.data
// }

export const getProviderAccessLogs = apiPost2('get-provider-access-logs')

export const getClientAccessLogs = apiPost2('get-client-access-logs')

export const fetchAlertIndicators = apiPost2('get-alert-event-groups')

export const fetchAlertIndicatorDetail = apiPost2('get-alert-event-group')

export const getAllProvidersByPagination = apiPost2('get-all-providers-pagination')

export const getAllClientsByPagination = apiPost2('get-all-clients-pagination')

export const getProviderDailyLogs = apiPost('get-provider-daily-logs')
