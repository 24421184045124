/* eslint-disable */
import { Button, Fab, Paper, Snackbar, Switch, TextField, Typography } from '@mui/material'
import React, {useContext, useEffect, useState} from 'react'
import {
  createRuleTarget,
  deleteRuleTarget,
  getRuleTargets,
  updateRuleTarget
} from '../../apiCalls/rulesApi'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import '../../globalStyle.scss'
import SearchModal from './SearchModal'
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined'
import {useApiGet, useApiPost} from "../../apiCalls/useApi";
import {OrgContext} from "../../Org";
import {ConfirmContext} from "../../Confirm";

export default function VipList({ruleId}: { ruleId: string }) {
  const [vipList, setVipList] = useState([])
  const [open, setOpen] = useState(false)
  const [chosenClient, setChosenClient] = useState({
    id: '',
    name: '',
  })

  const fetchRuleTargets = useApiGet(getRuleTargets)
  const addRuleTarget = useApiPost(createRuleTarget)
  const callUpdateRuleTarget = useApiPost(updateRuleTarget)
  const callDeleteRuleTarget = useApiPost(deleteRuleTarget)

  const { org } = useContext(OrgContext)
  const appId = org.apps?.[0]?.id

  const { openConfirm, closeConfirm, setConfirmSettings } = useContext(ConfirmContext)

  const reloadList = function () {
    fetchRuleTargets({
      appId,
      ruleId,
    }).then((res:any) => {
      if(!res || !res.data || res.error) return
      const {data} = res
      setVipList(data.find((e: any) => e.type === 'INCLUDE')?.targets || [])
    })
  }

  useEffect(() => {
    if (!appId) return
    reloadList()
  }, [appId])

  function toggleStatus(value: any) {
    return async () => {
      const newStatus = value.status === 'ON' ? 'OFF' : 'ON'
      const err = await callUpdateRuleTarget({
        appId,
        id: value.id,
        status: newStatus,
      })
      if (err) {
        return
      }
      // @ts-ignore
      setVipList((prev) => {
        return [
          // @ts-ignore
          ...prev.filter((e) => e.id !== value.id),
          {
            ...value,
            status: newStatus,
          },
        ]
      })
    }
  }

  function deleteVip(value: any) {
    return async () => {
      const err = await callDeleteRuleTarget({id: value.id, appId})
      if (err) {
        return
      }
      // @ts-ignore
      setVipList((prev) => {
        return [
          // @ts-ignore
          ...prev.filter((e) => e.id !== value.id),
        ]
      })
    }
  }

  function confirmToggleStatus(row: any) {
    return () => {
      setConfirmSettings({
        text: 'Toggle the status?',
        confirmCallback: toggleStatus(row),
      })
      openConfirm()
    }
  }

  function confirmDeleteVip(row: any) {
    return () => {
      setConfirmSettings({
        text: 'Delete this VIP?',
        confirmCallback: deleteVip(row),
      })
      openConfirm()
    }
  }

  const columns = [
    { field: 'targetId', headerName: 'Client ID', flex: 1 },
    { field: 'targetName', headerName: 'Client Name', flex: 1 },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params: any) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography variant='body2' color='textSecondary' style={{ marginRight: 8 }}>
            Off
          </Typography>
          <Switch checked={params.value === 'ON' ? true : false} onChange={confirmToggleStatus(params.row)}/>
          <Typography variant='body2' color='textSecondary' style={{ marginLeft: 8 }}>
            On
          </Typography>
        </div>
      ),
    },
    {
      field: 'delete',
      headerName: 'Delete',
      renderCell: (params: any) => <div
        className='buttonEnable exceptionListDeleteButton'
        onClick={confirmDeleteVip(params.row)}
      >Delete</div>,
    },
  ]

  const pressAddButton = () => {
    setOpen(true)
  }

  const onSubmit = async () => {
    const err = await addRuleTarget({
      appId,
      ruleId,
      type: 'INCLUDE',
      targetId: chosenClient.id,
      role: 'CLIENT',
      status: 'ON',
    })
    if (err) {
      return
    }
    reloadList()
    setChosenClient({ id: '', name: '' })
  }

  return (
    <Paper
      square
      elevation={0}
      style={{
        flex: 1,
        width: '99.5%',
        border: '2px solid gray',
        borderRadius: '6px',
        marginTop: '8px',
      }}
    >
      <div className='addProviderAndClientTitle'>VIP List</div>
      <div style={{ display: 'flex', alignItems: 'center', padding: '10px', marginTop: '20px' }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Fab
            variant='extended'
            sx={{backgroundColor:'#1876D1',color: '#ffffff',
            '&:hover': {
              backgroundColor: '#1d88f3',
            },}}
            onClick={() => {
              pressAddButton()
            }}
          >
            <PersonSearchOutlinedIcon />
            Choose Client
          </Fab>
          <TextField
            sx={{ marginLeft: '10px', width:'300px'}}
            id='standard-basic'
            label='Client'
            variant='outlined'
            value={chosenClient.id ? `${chosenClient.name} (${chosenClient.id})` : ''}
            disabled
          />
        </div>

        <div style={{ marginLeft: '10px' }}>
          <Button
            size='large'
            variant='contained'
            onClick={onSubmit}
          >
            Add
          </Button>
        </div>
      </div>

      <Paper
        elevation={0}
        style={{
          padding: '10px',
          // margin: '2px',
          height: 'calc(100vh - 390px)',
        }}
      >
        <DataGrid
          slots={{
            toolbar: GridToolbar,
          }}
          rows={vipList}
          // hideFooter
          columns={columns}
          sx={{
            border: 0,
            '& .MuiDataGrid-row:hover': {
              backgroundColor: 'rgba(235, 235, 235, 0.7)',
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#FFFFFF',
              color: '#5B5B5B',
              fontSize: '15px',
              fontFamily: 'Segoe UI',
              borderBottom: '2px solid #BABABA',
              // borderRadius: '15px',
            },
            '& .MuiDataGrid-cell': {
              borderBottom: `1px solid #BABABA`,
              color: '#000000',
              fontSize: '12px',
              fontFamily: 'Segoe UI',
              cursor: 'pointer',
              fontWeight: '600',
            },
            '& .MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            '& ::-webkit-scrollbar': {
              width: '5px',
            },
            '& ::-webkit-scrollbar-track': {
              backgroundColor: '#f1f1f1',
            },
            '& ::-webkit-scrollbar-thumb': {
              backgroundColor: '#888',
              borderRadius: '5px',
              '&:hover': {
                backgroundColor: '#555',
              },
            },
            '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
              color: '#5B5B5B',
            },
            '& .MuiDataGrid-toolbarContainer .MuiSvgIcon-root': {
              fill: '#5B5B5B',
            },
          }}
        />
      </Paper>
      <SearchModal open={open} setOpen={setOpen} type='Client' setChosenClient={setChosenClient} />
    </Paper>
  )
}
