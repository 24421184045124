/* eslint-disable */
import React, { useState } from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { LinearProgress, Paper } from '@mui/material'
import { ResponseData } from '../../utils/interfaces'
import { dataStyle } from './commonStyle'

export default function DashboardDataList({ isLoading, data, columns }: any) {
  const [selectedRow, setSelectedRow] = useState<ResponseData | null>(null)


  return (
    <div style={dataStyle}>
      <DataGrid
        hideFooter
        loading={isLoading}
        hideFooterSelectedRowCount
        slots={{
          loadingOverlay: LinearProgress,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        columns={columns}
        onRowClick={(params) => setSelectedRow(params.row)}
        rows={data || []}
        getRowId={(row) => row.id}
        sx={{
          width: '99%',
          border: 0,
          '& .MuiDataGrid-row:hover': {
            backgroundColor: 'rgba(235, 235, 235, 0.7)',
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#FFFFFF',
            color: '#5B5B5B',
            fontSize: '15px',
            fontFamily: 'Segoe UI',
            borderBottom: '2px solid #BABABA',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: `1px solid #BABABA`,
            fontSize: '12px',
            cursor: 'pointer',
            fontFamily: 'Segoe UI',
            fontWeight: '600',
          },
          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& ::-webkit-scrollbar': {
            width: '5px',
          },
          '& ::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1',
          },
          '& ::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '5px',
            '&:hover': {
              backgroundColor: '#555',
            },
          },
          '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
            color: '#5B5B5B',
          },
          '& .MuiDataGrid-toolbarContainer .MuiSvgIcon-root': {
            fill: '#5B5B5B',
          },
        }}
      />
    </div>
  )
}
